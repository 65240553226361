<template>
  <v-dialog v-model="ChangePasswordDialog" persistent max-width="400px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("Change Password") }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <form class="mt-5">
            <v-row class="space">
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("New Password") }}</label>
                <v-text-field
                  v-model="editedItem.new_password"
                  @focus="onFocus()"
                  solo
                  :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="() => (value = !value)"
                  :type="value ? 'password' : 'text'"
                ></v-text-field>
                <div v-if="validation_errors.new_password">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.new_password"
                    :key="index"
                  >{{ error }}</p>
                </div>
              </v-col>
              <!-- <v-col cols="1" md="1" sm="1">
              <div class="iconpassword">
                <v-icon @click="showPassword" v-if="passwordType == 'password'"
                  >visibility_off</v-icon
                >
                <v-icon @click="showPassword" v-if="passwordType == 'text'"
                  >visibility</v-icon
                >
              </div>
              </v-col>-->
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Confirm Password") }}</label>
                <v-text-field
                  v-model="editedItem.confirm_password"
                  solo
                  @focus="onFocus()"
                  type="password"
                  @paste.prevent
                ></v-text-field>
                <div v-if="validation_errors.confirm_password">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.confirm_password"
                    :key="index"
                  >{{ error }}</p>
                </div>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card-text>

      <v-card-actions class="space">
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel" @click="close">{{ $t("Close") }}</v-btn>
        <v-btn class="modal-btn-save" @click="save" :loading="loading" :disabled="loading">{{ $t("Save") }}</v-btn>
      </v-card-actions>
    </v-card>
    <!-- <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >


      <span class="white--text">
        {{ snackText }}
      </span>

      <v-btn text @click="snack = false">Close</v-btn>
    </v-snackbar>-->
  </v-dialog>
</template>

<script>
import axios from "axios";

import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: ["ChangePasswordDialog", "UserId"],
  name: "AddEditSubjectTypeComponent",
  mixins: [validationMixin],
  data() {
    return {
      userPassword: "",
      valid: true,
      value: true,

      //   passwordType: true,
      validation_errors: {
        new_password: "",
        confirm_password: "",
      },
      editedItem: {
        new_password: "",
        confirm_password: "",
      },
      loading: false,
      // snack: false,
      // snackColor: "",
      // snackText: "",
      // snackTime: 7000
    };
  },
  methods: {
    onFocus() {
      this.validation_errors = {
        new_password: "",
        confirm_password: "",
      };
    },
    showPassword() {
      if (this.passwordType == "text") {
        this.passwordType = "password";
      } else {
        this.passwordType = "text";
      }
    },
    close() {
      this.ChangePasswordDialog = false;
      this.validation_errors = {
        new_password: "",
        confirm_password: "",
      };
      this.editedItem = {
        new_password: "",
        confirm_password: "",
      };
      this.value = true;
      // this.snack = false;
      // this.snackColor = "";
      // this.snackText = "";
      this.$emit("childToParentPassword", false);
    },

    save() {
      this.loading = true;
      axios
        .post(
          this.getApiUrl + "/users/changePassword/" + this.UserId,
          this.editedItem,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.loading = false;
          if (response.data.status.error == true) {
            Object.assign(
              this.validation_errors,
              response.data.status.validation_errors
            );
          } else {
            // this.snack = true;
            // this.snackColor = "green";
            // this.snackText = response.data.status.message;
            this.close();
            this.$emit(
              "childToParentPassword",
              false,
              true,
              "green",
              response.data.status.message
            );
            location.reload();
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

.iconpassword {
  margin-top: 50% !important;
}
</style>
