<template>
  <v-dialog v-model="AddEditdialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(formTitle) }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" class="mt-5">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Name") }}</label>
                <v-text-field
                  v-model="editedItem.grade_name"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength50,
                  ]"
                  solo
                  @focus="onFocus()"
                ></v-text-field>
                <div v-if="validation_errors.grade_name">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.grade_name"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <!-- ar name -->
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Arabic Name") }}</label>
                <v-text-field
                  v-model="editedItem.ar_grade_name"
                  :rules="[
                    validationRules.maxLength50,
                    CheckArabicCharactersOnly(editedItem.ar_grade_name),
                  ]"
                  solo
                  @focus="onFocus()"
                ></v-text-field>
                <div v-if="validation_errors.ar_grade_name">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.ar_grade_name"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <!-- ar name -->
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Short Name") }}</label>
                <v-text-field
                  v-model="editedItem.short_name"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength5,
                  ]"
                  solo
                  @focus="onFocus()"
                ></v-text-field>
                <div v-if="validation_errors.short_name">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.short_name"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <v-col cols="12" sm="12">
                <label for="colors">{{ $t("Pickup a Color") }}</label>
                <div class="colors">
                  <span
                    v-for="(color, index) in colors"
                    :key="index"
                    class="colorItem"
                    :class="selectedColor == color ? 'active' : ''"
                    :style="{ backgroundColor: color }"
                    :id="index"
                    @click="selectColor(index, color)"
                  ></span>
                </div>
                <p class="error--text" v-if="colorError">{{ colorError }}</p>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel cancelBtn" @click="close">{{
          $t("Close")
        }}</v-btn>
        <v-btn
          class="modal-btn-save saveBtn"
          @click="save"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: [
    "editedItem",
    "AddEditdialog",
    "formTitle",
    "editedIndex",
    "endPoints",
  ],
  mixins: [validationMixin],
  name: "AddEditGradeComponents",
  data() {
    return {
      valid: true,
      validation_errors: {
        grade_name: "",
        short_name: "",
      },
      loading: false,
      colors: [
        "#5A86FF",
        "#1654FF",
        "#002FB1",
        "#A3A0FB",
        "#6863FE",
        "#4F4CC1",
        "#55D8FE",
        "#00BCF2",

        "#008BB4",
        "#FFB400",
        "#D49500",
        "#9C6E00",
        "#E3E3E3",
        "#8D8A8A",
        "#656565",
      ],
      selectedColor: "",
      colorError: "",
    };
  },
  watch: {
    AddEditdialog: {
      handler() {
        if (this.$refs.form) {
          this.resetValidation();
        }
        if (this.AddEditdialog == true && this.editedItem.color_hex) {
          this.selectedColor = this.editedItem.color_hex;
        }
      },
    },
  },
  methods: {
    onFocus() {
      this.validation_errors = {
        grade_name: "",
        short_name: "",
      };
    },
    close() {
      this.AddEditdialog = false;
      this.editedItem = {};
      this.validation_errors = {
        grade_name: "",
        short_name: "",
      };
      this.selectedColor = "";
      this.colorError = "";
      this.$emit("childToParent", false, null, this.editedIndex);
    },

    save() {
      if (this.valid == false) {
        this.validate();
      } else if (!this.selectedColor) {
        this.colorError = "Please select a color first";
      } else {
        this.loading = true;
        if (this.editedIndex > -1) {
          axios
            .post(
              this.getApiUrl +
                "/" +
                this.endPoints.edit +
                "/" +
                this.editedItem.id,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.loading = false;
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.AddEditdialog = false;
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
              }
              this.resetValidation();
            });
        } else {
          axios
            .post(
              this.getApiUrl + "/" + this.endPoints.create,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.editedItem = {
                  name: "",
                  notes: "",
                };
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
                this.close();
              }

              this.loading = false;
              this.resetValidation();
            });
        }
      }
    },
    selectColor(index, color) {
      console.log(index, color);
      this.selectedColor = color;
      this.editedItem.color_hex = this.selectedColor;
      this.colorError = "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

.colorItem {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 3px;
  cursor: pointer;
}
.active {
  border: 1px solid #000;
}
</style>
