<template>
  <div id="assignStudentModal">
    <v-dialog v-model="AddEditdialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">{{ $t("Add Students") }}</v-card-title>

        <v-card-text>
          <v-text-field
            v-model="search"
            :label="$i18n.t('Search by Name or ID')"
            prepend-icon="search"
          ></v-text-field>
          <p
            v-if="assignedStudents.length >= classData.student_count"
            class="text-center main-color"
          >
            {{ $t("You reach maximum student capacity") }}
          </p>
          <table class="table table-striped users-table">
            <tbody>
              <tr v-for="(student, i) in students" :key="i">
                <td>{{ student.code }}</td>
                <td>{{ student.student_full_name }}</td>
                <td>
                  <v-checkbox
                    v-model="assignedStudents"
                    :value="student.id"
                    class="mx-2"
                    label=""
                    :disabled="
                      assignedStudents.length >= classData.student_count &&
                      !assignedStudents.includes(student.id)
                    "
                  ></v-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel" @click="close">{{
            $t("Cancel")
          }}</v-btn>
          <v-btn
            class="modal-btn-save"
            :disabled="loading"
            :loading="loading"
            @click="save"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="color" :top="true" :right="true">
      {{ text }}
      <v-btn color="pink" text @click="snackbar = false">
        {{ $t("Close") }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: [
    "editedItem",
    "AddEditdialog",
    "formTitle",
    "editedIndex",
    "endPoints",
    "items",
  ],
  name: "AssignStudentToClass",
  mixins: [validationMixin],
  data() {
    return {
      students: [],
      assignedStudents: [],
      search: "",
      classData: {},
      loading: false,
      snackbar: false,
      color: "",
      text: "",
    };
  },
  watch: {
    AddEditdialog: {
      handler() {
        if (this.$refs.form) {
          this.getAllStudent();
        }
        // if (this.AddEditdialog == true) {
        //   this.assignedStudents = [];
        //   this.items.forEach((item) => {
        //     this.assignedStudents.push(item.id);
        //   });
        // }
      },
    },
    search() {
      this.getAllStudent();
    },
  },
  // computed: {
  //   allStudentFilter() {
  //     return this.students.filter(student => {
  //       if (this.search != "") {
  //         if (
  //           student.student_full_name
  //             .toLowerCase()
  //             .includes(this.search.toLowerCase())
  //         ) {
  //           return true;
  //         } else {
  //           return student.code
  //             .toLowerCase()
  //             .includes(this.search.toLowerCase());
  //         }
  //       } else {
  //         return true;
  //       }
  //     });
  //   }
  // },
  methods: {
    close() {
      this.editedItem = {};

      this.$emit("childToParent", false, null, this.editedIndex);
      this.search = "";
    },
    getAllStudent() {
      this.students = [];
      axios
        .get(
          this.getApiUrl +
            "/students/listStudentClasses/" +
            this.$router.currentRoute.params.classId +
            "?all_data" +
            "&filter_search=" +
            this.search,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.students = response.data.data.students;
          this.assignedStudents = response.data.data.studentIds;
          // this.getAssignedStudents();
        });
    },

    // getAssignedStudents() {
    //   // this.assignedStudents = [];
    //   let selected = this.assignedStudents;
    //   this.students.filter(function (student) {
    //     if (student.assigned == 1 && !selected.includes(student.id)) {
    //       selected.push(student.id);
    //     }
    //   });
    //   this.assignedStudents = selected;
    // },

    save() {
      this.loading = true;
      axios
        .post(
          this.getApiUrl + "/" + this.endPoints.create,
          { ids: this.assignedStudents },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.loading = false;
          if (response.data.status.error == false) {
            this.$emit(
              "childToParent",
              false,
              response.data.data,
              this.editedIndex
            );
            this.close();
          } else {
            // alert(response.data.status.message);
            this.color = "error";
            this.text = response.data.status.message;
            this.snackbar = true;
          }
        });
    },

    getClassData() {
      axios
        .get(
          this.getApiUrl +
            "/classes/show/" +
            this.$router.currentRoute.params.classId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.classData = response.data.data;
        });
    },
  },
  mounted() {
    this.getAllStudent();
    this.getClassData();
  },
};
</script>

<style lang="scss" scoped>
.table {
  height: auto;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f6ff;
}

.table-striped td {
  padding: 5px 5px;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}
</style>
