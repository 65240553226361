<template>
  <div id="bookTransaction">
    <v-dialog
      v-model="bookTransactionDialog"
      persistent
      max-width="500px"
      scrollable
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("Books Transaction") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row v-if="students.length > 0">
                <label>{{ $t("Transactions For") }}:</label>
              </v-row>
              <v-row>
                <div v-for="(student, index) in students" :key="index">
                  <v-chip
                    class="ma-2"
                    color="#e1e9ff"
                    text-color="#5A86FF"
                    close
                    @click:close="deleteStudent(index)"
                  >
                    {{ student.student_name }}
                    <!-- <v-icon>close</v-icon> <br /> -->
                  </v-chip>
                </div>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="search"
                    :label="$i18n.t('Search')"
                    prepend-icon="search"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" style="margin-top: 25px">
                  <v-checkbox
                    label="Auto Select"
                    v-model="autoSelect"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-autocomplete
                    :label="$i18n.t('Semester')"
                    :items="semesters"
                    @change="hideLabel = true && setSemesterName()"
                    item-text="name"
                    item-value="id"
                    v-model="semester"
                    solo
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-data-table
                  v-model="Bookselected"
                  :headers="headers"
                  :items="books"
                  class="elevation-1 level1"
                  loading-text="Loading... Please wait"
                  hide-default-footer
                  show-select
                  :disable-pagination="true"
                ></v-data-table>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  v-if="students.length > 0 && selectedStudent == null"
                >
                  {{ $t("Total Items") }} = {{ Bookselected.length }}
                  {{ $t("Items") }} * {{ students.length }}
                  {{ $t("Students") }} =
                  {{ Bookselected.length * students.length }}
                </v-col>
                <v-col cols="12" v-if="selectedStudent != null">
                  {{ $t("Total Items") }} = {{ Bookselected.length }}
                  {{ $t("Items * 1 Student") }} =
                  {{ Bookselected.length }}
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel cancelBtn" @click="close">{{
            $t("Cancel")
          }}</v-btn>

          <v-btn
            class="modal-btn-save saveBtn"
            @click="SendTransaction"
            :disabled="disabled"
            :loading="loading"
            >{{ $t("Make Transaction") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="300"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>{{
          $t("Are you sure you want to dispense selected books?")
        }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="dialog = false"
            id="noBtn"
            ref="always_focus"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="dispenseBooks"
            class="modal-btn-save yesBtn"
            :disabled="disabled"
            :loading="loading"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: [
    "bookTransactionDialog",
    "grade_id",
    "books",
    "students",
    "selectedStudent",
    // "semester_name",
  ],
  data() {
    return {
      disabled: false,
      dialog: false,
      snack: false,
      snackTime: 3000,
      snackColor: "",
      snackText: "",
      autoSelect: true,
      search: "",
      headers: [
        {
          text: "ID",
          value: "book_id",
          sortable: false,
        },
        {
          text: this.$i18n.t("Novel"),
          value: "book",
          sortable: false,
        },
        {
          text: this.$i18n.t("Semester"),
          value: "semester_name",
          sortable: false,
        },
        {
          text: this.$i18n.t("Quantity"),
          value: "quantity",
          sortable: false,
        },
      ],
      Bookselected: [],
      bookCount: 0,
      semesters: [],
      semester_name: "",
      semester: [],
      loading: false,
    };
  },
  watch: {
    // semester_name: {
    //   handler() {
    //     this.semester_name = this.semesters.find(
    //       (el) => (el.id = this.semester)
    //     );
    //   },
    //   deep: true,
    // },
    search: {
      handler() {
        this.getBooks();
      },
      deep: true,
    },
  },
  methods: {
    deleteStudent(index) {
      this.students.splice(index, 1);
    },
    setSemesterName() {
      let semesterId = this.semester;
      let semstername = this.semesters.find((el) => el.id == semesterId);
      this.semester_name = semstername.name;

      this.books.forEach((item) => {
        item.semester_name = semstername.name;
      });
    },
    SendTransaction() {
      if (this.students.length == 0 && this.selectedStudent == null) {
        this.snack = true;
        this.snackColor = "red";
        this.snackText = this.$i18n.t("Please select students first");
      } else {
        if (this.Bookselected.length == 0) {
          this.snack = true;
          this.snackColor = "red";
          this.snackText = this.$i18n.t("Please select books first");
        } else {
          this.dialog = true;
        }
      }
    },
    getSemesters() {
      this.loading = true;
      axios
        .get(this.getApiUrl + "/warehouses_transactions/getSemester", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.semesters = response.data.data;
          this.loading = false;
        });
    },
    getBooks() {
      this.loading = true;

      axios
        .post(
          this.getApiUrl +
            "/warehouses_transactions/BooksListQuantities/" +
            this.grade_id +
            "?search=" +
            this.search,
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.books = response.data.data;
          if (this.search != "" && this.autoSelect == true) {
            this.Bookselected = this.books;
          } else {
            this.Bookselected = [];
          }
          this.loading = false;
        });
    },
    dispenseBooks() {
      this.disabled = true;
      this.loading = true;
      axios
        .post(
          this.getApiUrl +
            "/warehouses_transactions/BooksListQuantitiesTransaction",

          {
            books: this.Bookselected,
            semester: this.semester,
            students: this.students,
            selectedStudent: this.selectedStudent,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.loading = false;
          if (response.data.status.error == false) {
            this.snack = true;
            this.snackColor = "green";
            this.snackText = "Transactions sent successfully";
            this.dialog = false;
            this.close();
          } else {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = response.data.status.message;
            this.dialog = false;
          }
          this.disabled = false;
        })
        .catch((err) => {
          this.snack = true;
          this.snackColor = "red";
          this.snackText = err;
          this.disabled = false;
        });
    },
    close() {
      this.bookTransactionDialog = false;

      this.$emit("childToParent", false, this.students);
    },
  },
  mounted() {
    this.getSemesters();
  },
};
</script>
