<template>
  <div>
    <v-menu
      open-on-hover
      top
      offset-y
      :close-on-content-click="false"
      :nudge-width="200"
      max-width="300px"
      v-if="isSuperAdmin()"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class="router-link">
          {{ studentName }}</span
        >
      </template>

      <v-card class="student-popup">
        <v-list>
          <v-list-item class="gray-background">
            <v-list-item-content class="py-0">
              <p class="mb-0 student-info">
                {{ item.StudentCode }}
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="py-0">
              <p class="mb-0 student-info">
                <strong>{{ $t("Name") }}: </strong>{{ item.StudentName }}
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="item.Grade">
            <v-list-item-content class="py-0">
              <p class="mb-0 student-info">
                <strong>{{ $t("Grade") }}: </strong>{{ item.Grade }}
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="item.class">
            <v-list-item-content class="py-0">
              <p class="mb-0 student-info">
                <strong>{{ $t("Class") }}: </strong>{{ item.class }}
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="my-1" v-if="item.FatherName"></v-divider>
          <v-list-item v-if="item.FatherName">
            <v-list-item-content class="py-0">
              <p class="mb-0 student-info">
                <strong>
                  {{ $t("Father") }}
                </strong>
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="item.FatherName">
            <v-list-item-content class="py-0">
              <p class="mb-0 student-info">
                <strong>{{ $t("Name") }}: </strong>{{ item.FatherName }}
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="item.FatherPhone">
            <v-list-item-content class="py-0">
              <p class="mb-0 student-info">
                <strong>{{ $t("Phone") }}: </strong>{{ item.FatherPhone }}
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="item.FatherEmail">
            <v-list-item-content class="py-0">
              <p class="mb-0 student-info">
                <strong>{{ $t("Email") }}: </strong>{{ item.FatherEmail }}
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="my-1" v-if="item.MotherName"></v-divider>
          <v-list-item v-if="item.MotherName">
            <v-list-item-content class="py-0">
              <p class="mb-0 student-info">
                <strong>
                  {{ $t("Mother") }}
                </strong>
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="item.MotherName">
            <v-list-item-content class="py-0">
              <p class="mb-0 student-info">
                <strong>{{ $t("Name") }}: </strong>{{ item.MotherName }}
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="item.MotherPhone">
            <v-list-item-content class="py-0">
              <p class="mb-0 student-info">
                <strong>{{ $t("Phone") }}: </strong>{{ item.MotherPhone }}
              </p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="item.MotherEmail">
            <v-list-item-content class="py-0">
              <p class="mb-0 student-info">
                <strong>{{ $t("Email") }}: </strong>{{ item.MotherEmail }}
              </p>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider class="my-1"></v-divider>

        <v-list>
          <v-list-item v-if="userId && isSuperAdmin()">
            <v-row>
              <v-col cols="8" class="py-0">
                <p
                  class="loginAsText mb-0 mt-1 student-action"
                  @click="loginAsUser(userId)"
                >
                  {{ $t("Login As Student") }}
                </p>
              </v-col>
              <v-col cols="4" class="py-0">
                <p class="loginAsIcon mb-0 text-right">
                  <v-btn
                    title="login as student"
                    @click="loginAsUser(userId)"
                    :disabled="loading"
                    :loading="loading"
                    icon
                  >
                    <v-icon>mdi-login</v-icon>
                  </v-btn>
                </p>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item v-if="archive != true">
            <v-row>
              <v-col cols="8" class="pt-0">
                <router-link
                  :to="`/students/${studentId}`"
                  class="router-link  loginAsText mb-0 mt-1 student-action"
                >
                  <!-- class=" "> -->
                  {{ $t("Open Student Profile") }}
                </router-link>
              </v-col>
              <v-col cols="4" class="pt-0">
                <p class="loginAsIcon mb-0 text-right">
                  <v-btn
                    title="Open Student Profile"
                    icon
                    :to="`/students/${studentId}`"
                  >
                    <v-icon>account_box</v-icon>
                  </v-btn>
                </p>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <span v-else
      ><span v-bind="attrs" v-on="on"> {{ studentName }}</span></span
    >
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "StudentHoverMenu",
  props: {
    studentName: {
      require: true,
      type: String,
    },
    studentId: {
      require: true,
      type: Number,
    },
    userId: {
      require: true,
      type: Number,
    },
    archive: {},
  },
  data() {
    return {
      item: {},
      loading: false,
    };
  },
  methods: {
    isSuperAdmin() {
      return localStorage.roles.includes("Super-Admin");
    },
    getStudentData() {
      axios
        .get(
          this.getApiUrl + "/students/getStudentPopUpData/" + this.studentId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.item = response.data.data;
        });
    },
    openStudentProfile() {
      console.log("asd");
      this.$router.push("/asd");
    },
    loginAsUser(userId) {
      this.loading = true;
      axios
        .get(this.getApiUrl + "/impersonate/" + userId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.loading = false;
          if (response.data.status.error == false) {
            let id = response.data.data.user.id;
            let token = response.data.data.access_token;
            let school = response.data.data.school;
            let logo = response.data.data.logo;
            let school_name = response.data.data.school_name;
            let gradeClass = response.data.data.gradeClass;
            localStorage.user_name = response.data.data.user.name;
            // localStorage to store user cradentials
            localStorage.id = id;
            localStorage.email = response.data.data.user.email;
            localStorage.lastLogin = response.data.data.lastLogin;
            localStorage.login = true;
            localStorage.token = token;
            localStorage.school = school;
            localStorage.logo = logo;
            localStorage.school_name = school_name;
            localStorage.gradeClass = gradeClass;
            localStorage.expiry = new Date().getTime() + 86400000; // 24 hours
            // localStorage.expiry = new Date().getTime() + 60000; // 1 minute
            localStorage.permissionsObject = JSON.stringify(
              response.data.data.permissions
            );
            localStorage.menus = JSON.stringify(response.data.data.menus);
            localStorage.roles = JSON.stringify(response.data.data.roles);
            if (
              response.data.data.roles &&
              response.data.data.roles.length == 1
            ) {
              localStorage.selectedRole = response.data.data.roles[0];
            } else {
              localStorage.selectedRole = "";
            }
            localStorage.selectedSomeRole = false;
            localStorage.loginAs = response.data.data.login_as;
            if (localStorage.loginAs) {
              localStorage.old_user = response.data.data.old_user;
              localStorage.old_user_name = response.data.data.old_user_name;
            }
            localStorage.updateData = response.data.data.updateData;
            this.$router.push({
              path: `/`,
            });
            // location.reload();
            window.location = "/";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getStudentData();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
.gray-background {
  background-color: #f1f1f1;
  padding: 10px 15px !important;
}

.loginAsText {
  color: $main-color !important;
  font-size: 1rem;
  margin-bottom: 0;
  cursor: pointer;
}

.student-popup {
  overflow: hidden !important;
  .student-info {
    font-size: 14px;
  }
  .student-action {
    font-size: 14px;
  }
}
</style>
