var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.AddEditdialog),callback:function ($$v) {_vm.AddEditdialog=$$v},expression:"AddEditdialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t(_vm.formTitle)))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",staticClass:"mt-5",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"contact-name"}},[_vm._v(_vm._s(_vm.$t("Name")))]),_c('v-text-field',{attrs:{"id":"tax-name","placeholder":"","rules":[
                  _vm.validationRules.required,
                  _vm.validationRules.maxLength50,
                ],"solo":"","dense":""},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.en.name),callback:function ($$v) {_vm.$set(_vm.editedItem.en, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.en.name"}}),(_vm.validation_errors.en.name)?_c('div',{staticClass:"vertical-space"},_vm._l((_vm.validation_errors.en.name),function(error,index){return _c('p',{key:index,staticClass:"red--text",on:{"focus":function($event){return _vm.onFocus()}}},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"contact-name"}},[_vm._v(_vm._s(_vm.$t("Arabic Name")))]),_c('v-text-field',{attrs:{"id":"tax-name","placeholder":"","rules":[
                  _vm.CheckArabicCharactersOnly(_vm.editedItem.ar.name),
                  _vm.validationRules.maxLength50,
                ],"solo":"","dense":""},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.ar.name),callback:function ($$v) {_vm.$set(_vm.editedItem.ar, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.ar.name"}}),(_vm.validation_errors.ar.name)?_c('div',{staticClass:"vertical-space"},_vm._l((_vm.validation_errors.ar.name),function(error,index){return _c('p',{key:index,staticClass:"red--text",on:{"focus":function($event){return _vm.onFocus()}}},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"percentage"}},[_vm._v(_vm._s(_vm.$t("Percentage")))]),_c('v-text-field',{staticStyle:{"align-self":"start","width":"30%"},attrs:{"id":"per-tax","placeholder":"","rules":[
                  _vm.validationRules.required,
                  _vm.validationRules.positiveFloatNumberWithOneDecimal,
                  _vm.validationRules.lessThanOrEqual(_vm.editedItem.amount, 100),
                ],"solo":"","dense":""},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.percentage),callback:function ($$v) {_vm.$set(_vm.editedItem, "percentage", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.percentage"}}),(_vm.validation_errors.percentage)?_c('div',{staticClass:"vertical-space"},_vm._l((_vm.validation_errors.percentage),function(error,index){return _c('p',{key:index,staticClass:"red--text",on:{"focus":function($event){return _vm.onFocus()}}},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1)]),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"form-group checkbox"},[_c('v-checkbox',{staticClass:"mx-2",attrs:{"label":_vm.$t('This tax is active')},model:{value:(_vm.editedItem.active),callback:function ($$v) {_vm.$set(_vm.editedItem, "active", $$v)},expression:"editedItem.active"}})],1)]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"notes"}},[_vm._v(_vm._s(_vm.$t("Notes")))]),_c('v-textarea',{attrs:{"solo":"","id":"notes","rows":"3"},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.notes),callback:function ($$v) {_vm.$set(_vm.editedItem, "notes", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.notes"}})],1)])],1)],1)],1),_c('v-card-actions',{staticClass:"space"},[_c('v-spacer'),_c('v-btn',{staticClass:"modal-btn-cancel",on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("Close")))]),_c('v-btn',{staticClass:"modal-btn-save",attrs:{"loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }