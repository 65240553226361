<template>
  <div>
    <DataTableComponent :table="table"></DataTableComponent>
  </div>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent";
import ACL from "../../acl";
export default {
  data() {
    return {
      table: {
        modal: "Invoice",
        path: "invoices",
        parent: ACL.Role("Parent"),
        headers: [
          {
            text: this.$i18n.t("Invoice Number"),
            value: "invoice_number",
          },
          {
            text: this.$i18n.t("Partner Code"),
            value: "partner_code",
          },
          {
            text: this.$i18n.t("Partner Name"),
            value: "partner_name",
          },
          {
            text: this.$i18n.t("Grade"),
            value: "student_grade",
            sortable: false,
          },
          {
            text: this.$i18n.t("Invoice Date"),
            value: "invoice_date",
          },
          {
            text: this.$i18n.t("Status"),
            value: "status",
          },
          {
            text: this.$i18n.t("Total"),
            value: "total",
          },
          {
            text: this.$i18n.t("Total After Discount"),
            value: "total_after_discount",
          },
          {
            text: this.$i18n.t("Payment Status"),
            value: "payment_status",
          },
          { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
        ],
        title: this.$i18n.t("Invoices"),
        apiEndPoints: {
          list: "invoices",
          //   create: "teachers/store",
          //   edit: "invoices/update",
          delete: "invoices/destroy",
        },
        editedItem: {
          name: "",
          email: "",
          mobile: "",
          gender: "",
          grades_id: [],
          subjects_id: [],
          country_code_id: 187,
        },
        permissions: {
          list: "list-invoices",
          add: "add-invoices",
          edit: "edit-invoices",
          delete: "delete-invoices",
          export: "export-invoices",
          print: "print-invoices",
        },
        filters: {
          status: [
            { id: "draft", name: this.$i18n.t("Draft") },
            { id: "issued", name: this.$i18n.t("Issued") },
            { id: "deleted", name: this.$i18n.t("Deleted") },
          ],
          grade_ids: [],
        },
      },
    };
  },
  methods: {},
  components: {
    DataTableComponent,
  },
};
</script>

<style></style>
