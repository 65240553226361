<template>
  <div id="PromoteStudent">
    <v-dialog v-model="promoteStudentsDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">
          {{ $t("Upgrade") }}
          <span class="extraSpace">
            {{ $t(studentName) }}
          </span>
          <!-- v-if="PromoteStudents.students_ids.length > 1"
          <span
            class="extraSpace"
            v-if="PromoteStudents.students_ids.length == 1"
          >
            {{ studentName }}</span
          > -->
        </v-card-title>
        <p class="text-center error--text">{{ $t(errorMsg) }}</p>

        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12" md="12">
                <label for="subject">{{ $t("Students") }}</label>

                <v-select
                  v-model="PromoteStudents.students_ids"
                  :items="students"
                  item-text="student_full_name"
                  item-value="id"
                  :rules="[studentRequiredRule]"
                  @change="onSelect"
                  multiple
                  chips
                  solo
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            PromoteStudents.students_ids.length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                          >{{ icon }}</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("Select All")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 2">
                      <span>{{ item.student_full_name }}</span>
                    </v-chip>
                    <span v-if="index == 2" class="grey--text caption"
                      >(+{{ PromoteStudents.students_ids.length - 2 }}
                      {{ $t("others") }} )</span
                    >
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <label for="academic Year">{{ $t("Academic Year") }}</label>
                <v-select
                  id="academicPeriods"
                  v-model="PromoteStudents.year_id"
                  :items="academicPeriods"
                  item-text="en.name"
                  item-value="id"
                  :rules="requiredRules"
                  solo
                  chips
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <label for="grades">{{ $t("Grade") }}</label>
                <v-select
                  v-model="PromoteStudents.grade_year_id"
                  :items="relatedGrades"
                  item-text="grade_name"
                  item-value="id"
                  :rules="requiredRules"
                  solo
                  chips
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <label for="grades">{{ $t("Class") }}</label>
                <v-select
                  v-model="PromoteStudents.class_id"
                  :items="classes"
                  item-text="name"
                  item-value="id"
                  solo
                  chips
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel" @click="close">{{
            $t("Cancel")
          }}</v-btn>
          <v-btn
            class="modal-btn-save"
            :disabled="loading"
            :loading="loading"
            @click="save"
            >{{ $t("Save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";

export default {
  props: ["promoteStudentsDialog", "selected"],
  name: "PromoteStudent",
  mixins: [validationMixin],
  data() {
    return {
      valid: true,
      requiredRules: [(v) => !!v || this.$i18n.t("This field is required")],
      errorMsg: "",

      studentName: "",
      academicId: "",
      students: [],
      academicPeriods: [],
      relatedGrades: [],
      classes: [],
      PromoteStudents: {
        students_ids: [],
        year_id: "",
        grade_year_id: "",
        class_id: "",
      },
      loading: false,
    };
  },
  computed: {
    selectAllStudents() {
      return this.PromoteStudents.students_ids.length === this.students.length;
    },
    selectSomeStudents() {
      return (
        this.PromoteStudents.students_ids.length > 0 && !this.selectAllStudents
      );
    },
    icon() {
      if (this.selectAllStudents) return "mdi-close-box";
      if (this.selectSomeStudents) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    selected: {
      handler() {
        this.PromoteStudents.students_ids = [];
        this.selected.forEach((student) => {
          this.PromoteStudents.students_ids.push(student.id);
        });
        this.getAllStudent();
        this.getAcademicPeriods();
      },
    },
    "PromoteStudents.students_ids": {
      handler() {
        let selectedIds = this.PromoteStudents.students_ids;
        if (this.PromoteStudents.students_ids.length == 1) {
          this.students.forEach((student) => {
            if (student.id == selectedIds[0]) {
              this.studentName = student.student_full_name;
            }
          });
        } else {
          this.studentName = "Students";
        }
      },
    },
    "PromoteStudents.year_id": {
      handler() {
        this.relatedGrades = [];
        this.PromoteStudents.grade_year_id = "";
        this.PromoteStudents.class_id = "";
        this.getRelatedGrades();
      },
    },
    "PromoteStudents.grade_year_id": {
      handler() {
        this.classes = [];
        this.PromoteStudents.class_id = "";
        this.getClassesObj();
      },
    },
  },

  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllStudents) {
          this.PromoteStudents.students_ids = [];
        } else {
          let all = this.students;
          let selected = [];

          all.forEach((student) => {
            selected.push(student.id);
          });
          // this.allSubjects.students_ids = this.students.slice();
          this.PromoteStudents.students_ids = selected;
        }
      });
    },
    close() {
      this.errorMsg = "";
      this.PromoteStudents = {
        students_ids: [],
        year_id: "",
        grade_year_id: "",
        class_id: "",
        search: "",
      };
      this.$refs.form.resetValidation();
      this.$emit("childToParent");
    },
    save() {
      if (this.valid == true) {
        this.loading = true;
        axios
          .post(
            this.getApiUrl + "/students/upgradeStudentToNewGrade",
            this.PromoteStudents,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.loading = false;
            if (response.data.status.error == true) {
              this.errorMsg = response.data.status.message;
            } else {
              this.close();
              window.location.reload();
            }
          });
      } else {
        this.$refs.form.validate();
      }
    },

    getAllStudent() {
      axios
        .get(
          this.getApiUrl +
            "/students/listStudentClasses/" +
            this.$router.currentRoute.params.classId +
            "?all_data&only_assigned" +
            "&filter_search=",
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.students = response.data.data.students;
        });
    },

    getAcademicPeriods() {
      axios
        .get(this.getApiUrl + "/school/showAcademicPeriod?list=yes", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          let allAcademic = response.data.data;
          let currentYear = this.academicId;

          this.academicPeriods = allAcademic.filter((academic) => {
            return academic.id != currentYear;
          });
        });
    },

    getRelatedGrades() {
      if (this.PromoteStudents.year_id) {
        let yearId = this.PromoteStudents.year_id;
        axios
          .get(this.getApiUrl + "/school/getAcademicPeriodGrade/" + yearId, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            this.relatedGrades = response.data.data;
          });
      }
    },

    getClassesObj() {
      let gradesArray = [];
      gradesArray.push(this.PromoteStudents.grade_year_id);

      if (this.PromoteStudents.grade_year_id != "") {
        let filter = {
          name: "",
          academic_grade_id: gradesArray,
        };
        let yearId = this.PromoteStudents.year_id;
        axios
          .get(this.getApiUrl + "/classes/nested/" + yearId + "?all_data", {
            params: {
              filter: filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          })
          .then((response) => {
            this.classes = response.data.data;
          });
      }
    },
    onSelect() {
      this.errorMsg = "";
    },

    /* validation */
    studentRequiredRule() {
      if (this.PromoteStudents.students_ids.length < 1) {
        return this.$i18n.t("This field is required");
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.PromoteStudents.students_ids = [];
    this.selected.forEach((student) => {
      this.PromoteStudents.students_ids.push(student.id);
    });
    this.getAllStudent();
    this.getAcademicPeriods();
    // this.PromoteStudents.class_id = this.$router.currentRoute.params.classId;
    this.academicId = this.$router.currentRoute.params.academicYear;
  },
};
</script>

<style lang="scss" scoped>
.extraSpace {
  margin-left: 6px;
}
</style>
