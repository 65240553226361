<template>
  <v-dialog v-model="AddEditdialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(formTitle) }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" class="mt-5">
            <v-row class="space">
              <v-col cols="12" sm="12" md="12">
                <!-- <label
                    for="group"
                    class="v-label theme--light"
                    style="font-size: 16px !important;"
                    >Partner Group</label
                  >
                  <select
                    class="custom-select"
                    id="group_id"
                    name="group_id"
                    v-model.trim="editedItem.group_id"
                    @focus="onFocus()"
                  >
                    <option value=""></option>
                    <option
                      v-for="(group, index) in groups"
                      :key="index"
                      :value="group.id"
                      :selected="group.id == editedItem.group_id"
                      >{{ group.name }}</option
                    >
                  </select> -->
                <label>{{ $t("Partner Group") }}</label>
                <v-select
                  :items="groups"
                  item-text="name"
                  item-value="id"
                  v-model="editedItem.group_id"
                  :rules="[validationRules.required]"
                  solo
                  required
                  @focus="onFocus()"
                ></v-select>
                <div v-if="validation_errors.group_id">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.group_id"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Partner Name") }}</label>
                <v-text-field
                  v-model.trim="editedItem.partner_name"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength50
                  ]"
                  solo
                  @focus="onFocus()"
                ></v-text-field>
                <div v-if="validation_errors.name">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.name"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Tax Registration") }} #</label>
                <v-text-field
                  v-model.trim="editedItem.tax_registration"
                  :rules="[validationRules.maxLength50]"
                  solo
                  @focus="onFocus()"
                ></v-text-field>
                <div v-if="validation_errors.tax_registration">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.tax_registration"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <label class="radio-label">{{ $t("Partner Type") }}</label>
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <v-radio-group
                  v-model="editedItem.type"
                  :rules="[validationRules.required]"
                  row
                >
                  <v-radio
                    :label="$t('Company')"
                    value="Company"
                    @change="onFocus"
                  ></v-radio>
                  <v-radio
                    :label="$t('Individual')"
                    value="Individual"
                    @change="onFocus"
                  ></v-radio>
                </v-radio-group>
                <div v-if="validation_errors.type">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.type"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <v-col sm="4" md="4" xs="4" cols="4">
                <!-- <div id="country-code"> -->
                <label>{{ $t("Code") }}</label>
                <v-autocomplete
                  :items="Codes"
                  required
                  @change="hideLabel = true"
                  item-text="title"
                  item-value="value"
                  v-model="editedItem.country_code_id"
                  solo
                  ><template slot="selection" slot-scope="data">
                    <!-- <img src="../../assets/flags/AF.png" />
                    {{ data.title }} -->

                    <v-flex xs3>
                      <v-avatar size="25">
                        <img :src="data.item.icon" />
                      </v-avatar>
                    </v-flex>
                    <v-flex class="ml-3">
                      {{ data.item.title }}
                    </v-flex>
                  </template>
                  <template slot="item" slot-scope="data">
                    <v-list-item-avatar
                      style="width: 20px; min-width: 20px; height: 20px;"
                    >
                      <img :src="data.item.icon" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.title"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <!-- </div> -->

                <div v-if="validation_errors.country_code_id">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.country_code_id"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col sm="8" md="8" xs="8" cols="8" style="margin-top:-10px;">
                <label>{{ $t("Mobile") }}</label>
                <v-text-field
                  v-model.trim="editedItem.mobile"
                  @focus="onFocus()"
                  :rules="[
                    validationRules.required,
                    mobileRule(editedItem.country_code_id, editedItem.mobile)
                  ]"
                  solo
                ></v-text-field>
                <div v-if="validation_errors.mobile">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.mobile"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Email") }}</label>
                <v-text-field
                  v-model.trim="editedItem.email"
                  :rules="[validationRules.email]"
                  solo
                  @focus="onFocus()"
                ></v-text-field>
                <div v-if="validation_errors.email">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.email"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Notes") }}</label>
                <v-textarea
                  v-model="editedItem.notes"
                  solo
                  rows="1"
                  style="font-size: 13px;"
                ></v-textarea>
                <div v-if="validation_errors.notes">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.notes"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="space">
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel" @click="close">{{ $t("Close") }}</v-btn>
        <v-btn
          class="modal-btn-save"
          @click="save"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: [
    "editedItem",
    "AddEditdialog",
    "formTitle",
    "editedIndex",
    "endPoints"
  ],
  name: "AddEditPartnersComponents",
  mixins: [validationMixin],
  data() {
    return {
      valid: true,
      validation_errors: {
        name: "",
        group_id: "",
        email: "",
        mobile: "",
        tax_registration: ""
      },
      loading: false,
      groups: [],
      Codes: []
    };
  },
  watch: {
    AddEditdialog: {
      handler() {
        if (this.$refs.form) {
          this.resetValidation();
        }
      }
    }
  },
  computed: {
    propEditedItem: {
      get: function() {
        return this.editedItem;
      },
      set: function(value) {
        this.editedItem = value;
        return this.editedItem;
      }
    }
  },
  methods: {
    onFocus() {
      this.validation_errors = {
        name: "",
        group_id: "",
        email: "",
        mobile: "",
        tax_registration: ""
      };
    },
    close() {
      this.AddEditdialog = false;
      this.validation_errors = {
        name: "",
        group_id: "",
        email: "",
        mobile: "",
        tax_registration: ""
      };
      this.editedItem = {};
      this.$emit("childToParent", false, null, this.editedIndex);
    },

    save() {
      if (this.valid == false) {
        this.validate();
      } else {
        this.loading = true;
        this.editedItem.name = this.editedItem.partner_name;
        if (this.editedIndex > -1) {
          axios
            .post(
              this.getApiUrl +
                "/" +
                this.endPoints.edit +
                "/" +
                this.editedItem.id,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token
                }
              }
            )
            .then(response => {
              this.loading = false;
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.AddEditdialog = false;
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
              }
            });
        } else {
          axios
            .post(
              this.getApiUrl + "/" + this.endPoints.create,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token
                }
              }
            )
            .then(response => {
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.editedItem = {
                  name: "",
                  code: "",
                  students_count: "",
                  academic_grade_id: "",
                  teacher_id: ""
                };
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
                this.close();
              }

              this.loading = false;
            });
        }
      }
    }
  },
  mounted() {
    axios
      .get(this.getApiUrl + "/partners/getPartnerGroups", {
        headers: {
          Authorization: "Bearer " + localStorage.token
        }
      })
      .then(response => {
        this.groups = response.data.data;
      });

    axios.get(this.getApiUrl + "/getCodes").then(response => {
      this.Codes = response.data.data;
    });
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
</style>
