<template>
  <div id="cashpointsModal">
    <v-dialog
      v-model="AddEditdialog"
      persistent
      max-width="500px"
      class="cashpoints"
      scrollable
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t(formTitle) }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row class="space">
              <v-col cols="12" sm="12" md="12">
                <v-tabs v-model="tab" background-color="primary" dark>
                  <v-tab> {{ $t("Details") }} </v-tab>
                  <v-tab> {{ $t("Assignee") }} </v-tab>
                </v-tabs>
                <v-card>
                  <v-tabs-items v-model="tab">
                    <v-form ref="form" v-model="valid">
                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <div class="form-group">
                              <label for="contact-name">{{ $t("Name") }}</label>
                              <v-text-field
                                id="tax-name"
                                placeholder=""
                                v-model.trim="editedItem.en.name"
                                @focus="onFocus()"
                                :rules="[
                                  validationRules.required,
                                  validationRules.maxLength50,
                                ]"
                                solo
                                dense
                              ></v-text-field>
                            </div>
                            <div class="form-group">
                              <label for="contact-name">{{
                                $t("Arabic Name")
                              }}</label>
                              <v-text-field
                                id="tax-name"
                                placeholder=""
                                v-model.trim="editedItem.ar.name"
                                @focus="onFocus()"
                                :rules="[
                                  CheckArabicCharactersOnly(editedItem.ar.name),
                                  validationRules.maxLength50,
                                ]"
                                solo
                                dense
                              ></v-text-field>
                            </div>

                            <div class="form-group">
                              <label for="contact-name">{{ $t("Code") }}</label>
                              <v-text-field
                                id="tax-name"
                                placeholder=""
                                v-model.trim="editedItem.code"
                                @focus="onFocus()"
                                :rules="[
                                  validationRules.required,
                                  validationRules.maxLength50,
                                ]"
                                solo
                                dense
                              ></v-text-field>
                            </div>

                            <v-row>
                              <v-col cols="12" sm="6">
                                <div class="form-group">
                                  <label for="gender">{{ $t("Type") }}</label>
                                  <div class="flex-center">
                                    <v-radio-group
                                      v-model="editedItem.type"
                                      row
                                      @change="changType()"
                                    >
                                      <v-radio
                                        :label="$t('Cash')"
                                        value="Cash"
                                      ></v-radio>
                                      <v-radio
                                        :label="$t('Bank')"
                                        value="Bank"
                                      ></v-radio>
                                    </v-radio-group>
                                  </div>
                                </div>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <div class="form-group">
                                  <label for="currency">{{
                                    $t("Select Currency")
                                  }}</label>
                                  <v-select
                                    :items="currencies"
                                    v-model="editedItem.currency"
                                    chips
                                    solo
                                    item-text="text"
                                    item-value="id"
                                  ></v-select>
                                </div>
                              </v-col>
                            </v-row>

                            <div class="form-group">
                              <label for="select payment">{{
                                $t("Select Payment Method")
                              }}</label>
                              <v-select
                                :items="paymentMethods"
                                v-model="editedItem.payment_method"
                                :rules="[
                                  checkMultiSelect(editedItem.payment_method),
                                ]"
                                chips
                                solo
                                multiple
                                item-text="text"
                                item-value="id"
                              ></v-select>
                            </div>

                            <div class="form-group checkbox">
                              <v-checkbox
                                v-model="editedItem.active"
                                class="mx-2"
                                :label="$t('This cash point is active')"
                              ></v-checkbox>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <div
                              class="icon-sec text-center assignee-iconDiv"
                              v-if="editedIndex == -1 && show == false"
                            >
                              <v-icon
                                large
                                class="main-color"
                                @click="showTable"
                                >add_circle</v-icon
                              >
                              <p class="text-center main-color mt-4">
                                {{ $t("Add Assignee") }}
                              </p>
                            </div>

                            <div v-if="show || editedIndex != -1">
                              <v-text-field
                                v-model="search"
                                :label="$t('Search by Name or ID')"
                                prepend-icon="search"
                              ></v-text-field>

                              <div class="table-wrapper">
                                <table class="table table-striped users-table">
                                  <thead>
                                    <tr
                                      :class="
                                        currentAppLocale == 'ar'
                                          ? 'text-right'
                                          : ''
                                      "
                                    >
                                      <th>{{ $t("ID") }}</th>
                                      <th>{{ $t("Name") }}</th>
                                      <th>{{ $t("Actions") }}</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr
                                      v-for="user in allUsersFilter"
                                      :key="user.id"
                                      :class="
                                        currentAppLocale == 'ar'
                                          ? 'text-right'
                                          : ''
                                      "
                                    >
                                      <td>{{ user.id }}</td>
                                      <td>{{ user.name }}</td>
                                      <td>
                                        <v-checkbox
                                          v-model="editedItem.users"
                                          :value="user.id"
                                          class="mx-2"
                                          label=""
                                        ></v-checkbox>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div class="text-center">
                                  <v-icon
                                    large
                                    class="main-color"
                                    @click="getAllUsersMenu"
                                    v-if="editedIndex != -1"
                                    :class="{ hidden: showAllUsers }"
                                    >add_circle</v-icon
                                  >
                                </div>
                              </div>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-form>
                  </v-tabs-items>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="modal-btn-save previous"
            @click="tab = 0"
            v-if="tab == 1"
            >{{ $t("Previous") }}</v-btn
          >
          <v-btn class="modal-btn-cancel cancelBtn" @click="close">{{
            $t("Cancel")
          }}</v-btn>
          <v-btn class="modal-btn-save" @click="tab = 1" v-if="tab == 0">{{
            $t("Next")
          }}</v-btn>
          <v-btn
            class="modal-btn-save saveBtn"
            @click="save"
            :loading="loading"
            :disabled="loading"
            v-if="tab == 1"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snack" :color="snackColor" :right="true" :top="true">
      <!-- {{ snackText }} -->

      <span class="white--text">
        {{ snackText }}
      </span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: [
    "editedItem",
    "AddEditdialog",
    "formTitle",
    "editedIndex",
    "endPoints",
  ],
  mixins: [validationMixin],
  name: "AddEditCashPointComponents",
  data() {
    return {
      valid: true,
      validation_errors: {
        en: {
          name: "",
        },
        ar: {
          name: "",
        },
      },
      loading: false,
      tab: null,
      currencies: [
        {
          id: "EGP",
          text: this.$i18n.t("EGP"),
        },
        {
          id: "SAR",
          text: this.$i18n.t("SAR"),
        },
        {
          id: "DOLLAR",
          text: this.$i18n.t("DOLLAR"),
        },
      ],
      users: [],
      search: "",
      show: false,
      snack: false,
      snackText: "",
      snackColor: "",
      selected: "",
      usersObjects: [],
      showAllUsers: false,
    };
  },
  computed: {
    allUsersFilter() {
      return this.usersObjects.filter((user) => {
        if (!this.search) {
          return user.name.toLowerCase().includes(this.search.toLowerCase());
        } else if (this.search && isNaN(this.search)) {
          return user.name.toLowerCase().includes(this.search.toLowerCase());
        } else if (this.search && !isNaN(this.search)) {
          if (this.search == user.id) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
    },
    paymentMethods: function () {
      return this.editedItem.type == "Bank"
        ? [
            {
              id: "Transfer",
              text: this.$i18n.t("Transfer"),
            },
            {
              id: "POS",
              text: this.$i18n.t("POS"),
            },
            {
              id: "Online Payment",
              text: this.$i18n.t("Online Payment"),
            },
            {
              id: "Check",
              text: this.$i18n.t("Check"),
            },
          ]
        : this.editedItem.type
        ? [{ id: "Cash", text: this.$i18n.t("Cash") }]
        : [];
    },
  },
  watch: {
    AddEditdialog: function () {
      if (this.$refs.form) {
        this.resetValidation();
      }

      if (this.editedIndex != -1) {
        console.log("> -1");
        this.usersObjects = [];
        let selectedUsers = this.editedItem.users;
        selectedUsers.forEach((selected) => {
          // console.log(selected);
          if (this.users.find((user) => user.id == selected) != undefined) {
            this.usersObjects.push(
              this.users.find((user) => user.id == selected)
            );
          }
        });
      } else {
        console.log("0");
        this.usersObjects = [];
        this.usersObjects = this.users;
      }
    },
  },
  methods: {
    onFocus() {
      this.validation_errors = {
        grade_name: "",
        short_name: "",
      };
    },
    close() {
      this.tab = null;
      this.show = false;
      this.AddEditdialog = false;
      this.editedItem = {};
      this.search = "";
      this.validation_errors = {};
      this.$emit("childToParent", false, null, this.editedIndex);
      this.paymentMethods = [];
      this.showAllUsers = false;
    },

    save() {
      if (this.valid == false) {
        this.validate();
        this.tab = 0;
      } else {
        this.loading = true;
        if (this.editedIndex > -1) {
          axios
            .post(
              this.getApiUrl +
                "/" +
                this.endPoints.edit +
                "/" +
                this.editedItem.id,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.loading = false;
              this.resetValidation();
              if (response.data.status.error == true) {
                this.search = "";
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );

                if (this.validation_errors.users) {
                  this.tab = 1;

                  this.snack = true;
                  this.snackText = this.$i18n.t(
                    "Cannot save, please assign a user to this cash point"
                  );
                  this.snackColor = "red";
                } else {
                  this.snack = true;
                  this.snackText = response.data.status.message;
                  this.snackColor = "red";
                }

                if (
                  this.validation_errors.en.name ||
                  this.validation_errors.code ||
                  this.validation_errors.payment_method ||
                  this.validation_errors.type
                ) {
                  this.tab = 0;
                }
              } else {
                this.AddEditdialog = false;

                this.search = "";
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
                this.tab = 0;
                this.showAllUsers = false;
                this.paymentMethods = [];
                this.validation_errors = {};
              }
            });
        } else {
          axios
            .post(
              this.getApiUrl + "/" + this.endPoints.create,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.resetValidation();
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );

                if (this.validation_errors.users) {
                  this.tab = 1;

                  this.snack = true;
                  this.snackText = this.$i18n.t(
                    "Cannot save, please assign a user to this cash point"
                  );
                  this.snackColor = "red";
                } else {
                  this.snack = true;
                  this.snackText = response.data.status.message;
                  this.snackColor = "red";
                }

                if (
                  this.validation_errors.en.name ||
                  this.validation_errors.code ||
                  this.validation_errors.payment_method ||
                  this.validation_errors.type
                ) {
                  this.tab = 0;
                }
              } else {
                this.editedItem = {
                  name: "",
                  notes: "",
                };
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
                this.search = "";
                this.close();
                this.paymentMethods = [];
                this.validation_errors = {};
              }

              this.loading = false;
              this.tab = 0;
              this.showAllUsers = false;
            });
        }
      }
    },

    getAllUsers() {
      axios
        .get(this.getApiUrl + "/users?role=Accountant", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          console.log(response);
          this.users = response.data.data;
        });
    },

    getAllUsersMenu() {
      this.usersObjects = this.users;
      this.showAllUsers = true;
    },

    showTable() {
      this.show = true;
    },

    changType() {
      console.log(this.editedItem.type);

      if (this.editedItem.type == "Bank") {
        this.editedItem.payment_method = [];
        // this.paymentMethods = ["Transfer", "POS", "Online Payment", "Check"];
      }
      if (this.editedItem.type == "Cash") {
        this.editedItem.payment_method = [];
        // this.paymentMethods = ["Cash"];
      }
    },
    getId(id) {
      let AllUsers = this.users;
      return AllUsers.find((user) => user.id == id).id;
    },
    getName(id) {
      let AllUsers = this.users;
      return AllUsers.find((user) => user.id == id).name;
    },
  },
  mounted() {
    this.getAllUsers();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

.v-card__title {
  margin-bottom: 15px;
}

.icon-sec {
  height: 360px;
  padding-top: 50px;
}

.table {
  height: auto;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f6ff;
}

.table-striped td {
  padding: 5px 5px;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}

.assignee-iconDiv {
  height: 360px;
}
.table-wrapper {
  height: 300px;
  position: relative;
  overflow: auto;
}
.v-card__title {
  margin-bottom: 0 !important;
}
.v-dialog label {
  margin-top: 15px !important;
  margin-bottom: 7px !important;
}
.modal-btn-save {
  position: relative;
  right: 0;
}
</style>
