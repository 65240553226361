var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.AddEditdialog),callback:function ($$v) {_vm.AddEditdialog=$$v},expression:"AddEditdialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t(_vm.formTitle)))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",staticClass:"mt-5",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Name")))]),_c('v-text-field',{attrs:{"rules":[
                  _vm.validationRules.required,
                  _vm.validationRules.maxLength50,
                ],"solo":""},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.grade_name),callback:function ($$v) {_vm.$set(_vm.editedItem, "grade_name", $$v)},expression:"editedItem.grade_name"}}),(_vm.validation_errors.grade_name)?_c('div',_vm._l((_vm.validation_errors.grade_name),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Arabic Name")))]),_c('v-text-field',{attrs:{"rules":[
                  _vm.validationRules.maxLength50,
                  _vm.CheckArabicCharactersOnly(_vm.editedItem.ar_grade_name),
                ],"solo":""},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.ar_grade_name),callback:function ($$v) {_vm.$set(_vm.editedItem, "ar_grade_name", $$v)},expression:"editedItem.ar_grade_name"}}),(_vm.validation_errors.ar_grade_name)?_c('div',_vm._l((_vm.validation_errors.ar_grade_name),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',[_vm._v(_vm._s(_vm.$t("Short Name")))]),_c('v-text-field',{attrs:{"rules":[
                  _vm.validationRules.required,
                  _vm.validationRules.maxLength5,
                ],"solo":""},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.short_name),callback:function ($$v) {_vm.$set(_vm.editedItem, "short_name", $$v)},expression:"editedItem.short_name"}}),(_vm.validation_errors.short_name)?_c('div',_vm._l((_vm.validation_errors.short_name),function(error,index){return _c('p',{key:index,staticClass:"red--text"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('label',{attrs:{"for":"colors"}},[_vm._v(_vm._s(_vm.$t("Pickup a Color")))]),_c('div',{staticClass:"colors"},_vm._l((_vm.colors),function(color,index){return _c('span',{key:index,staticClass:"colorItem",class:_vm.selectedColor == color ? 'active' : '',style:({ backgroundColor: color }),attrs:{"id":index},on:{"click":function($event){return _vm.selectColor(index, color)}}})}),0),(_vm.colorError)?_c('p',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.colorError))]):_vm._e()])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"modal-btn-cancel cancelBtn",on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("Close")))]),_c('v-btn',{staticClass:"modal-btn-save saveBtn",attrs:{"loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }