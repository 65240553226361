import { render, staticRenderFns } from "./addEditSubjectComponent.vue?vue&type=template&id=377117fc&scoped=true&"
import script from "./addEditSubjectComponent.vue?vue&type=script&lang=js&"
export * from "./addEditSubjectComponent.vue?vue&type=script&lang=js&"
import style0 from "./addEditSubjectComponent.vue?vue&type=style&index=0&id=377117fc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "377117fc",
  null
  
)

export default component.exports