<template>
  <v-dialog v-model="AddEditdialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(formTitle) }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" class="mt-5">
            <v-col cols="12" sm="12" md="12">
              <div class="form-group">
                <label for="contact-name">{{ $t("Name") }}</label>
                <v-text-field
                  id="tax-name"
                  placeholder=""
                  v-model.trim="editedItem.en.name"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength50,
                  ]"
                  @focus="onFocus()"
                  solo
                  dense
                ></v-text-field>

                <div v-if="validation_errors.en.name" class="vertical-space">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.en.name"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <div class="form-group">
                <label for="contact-name">{{ $t("Arabic Name") }}</label>
                <v-text-field
                  id="tax-name"
                  placeholder=""
                  v-model.trim="editedItem.ar.name"
                  :rules="[
                    CheckArabicCharactersOnly(editedItem.ar.name),
                    validationRules.maxLength50,
                  ]"
                  @focus="onFocus()"
                  solo
                  dense
                ></v-text-field>

                <div v-if="validation_errors.ar.name" class="vertical-space">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.ar.name"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <div class="form-group">
                <label for="percentage">{{ $t("Percentage") }}</label>
                <v-text-field
                  style="align-self: start; width: 30%"
                  id="per-tax"
                  placeholder=""
                  v-model.trim="editedItem.percentage"
                  :rules="[
                    validationRules.required,
                    validationRules.positiveFloatNumberWithOneDecimal,
                    validationRules.lessThanOrEqual(editedItem.amount, 100),
                  ]"
                  @focus="onFocus()"
                  solo
                  dense
                ></v-text-field>
                <div v-if="validation_errors.percentage" class="vertical-space">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.percentage"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </div>
            </v-col>

            <v-col class="d-flex" cols="12" sm="12">
              <div class="form-group checkbox">
                <v-checkbox
                  v-model="editedItem.active"
                  class="mx-2"
                  :label="$t('This tax is active')"
                ></v-checkbox>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <div class="form-group">
                <label for="notes">{{ $t("Notes") }}</label>

                <v-textarea
                  solo
                  id="notes"
                  rows="3"
                  v-model.trim="editedItem.notes"
                  @focus="onFocus()"
                ></v-textarea>
              </div>
            </v-col>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="space">
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel" @click="close">{{ $t("Close") }}</v-btn>
        <v-btn
          class="modal-btn-save"
          @click="save"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// $(document).ready(function() {
//   document.getElementsByClassName(".v-cart")[0].scrollTop = 0;
// });

import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: [
    "editedItem",
    "AddEditdialog",
    "formTitle",
    "editedIndex",
    "endPoints",
  ],
  name: "addEditTaxComponent",
  mixins: [validationMixin],
  data() {
    return {
      valid: true,
      validation_errors: {
        id: "",
        name: "",
        percentage: "",
        notes: "",
        active: "",
        countries: [],
        en: {
          name: "",
        },
        ar: {
          name: "",
        },
      },
      loading: false,
      categories: [],
      countries: [],
      selectedCountries: [],
    };
  },
  computed: {
    selectAllCountries() {
      return this.editedItem.countries.length === this.countries.length;
    },
    selectSomeCountries() {
      return this.editedItem.countries.length > 0 && !this.selectAllCountries;
    },
    icon() {
      if (this.selectAllCountries) return "mdi-close-box";
      if (this.selectSomeCountries) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  watch: {
    AddEditdialog: {
      handler() {
        if (this.$refs.form) {
          this.resetValidation();
        }
      },
    },
  },
  methods: {
    onFocus() {
      this.validation_errors = {
        id: "",
        name: "",
        percentage: "",
        notes: "",
        active: "",
        countries: [],
        en: {
          name: "",
        },
        ar: {
          name: "",
        },
      };
    },
    close() {
      this.AddEditdialog = false;
      this.validation_errors = {
        id: "",
        name: "",
        percentage: "",
        notes: "",
        active: "",
        countries: [],
        en: {
          name: "",
        },
        ar: {
          name: "",
        },
      };
      this.editedItem = {};
      this.$emit("childToParent", false, null, this.editedIndex);

      this.getAllCountries();
    },

    save() {
      if (this.valid == false) {
        this.validate();
      } else {
        this.loading = true;
        if (this.editedIndex > -1) {
          axios
            .post(
              this.getApiUrl +
                "/" +
                this.endPoints.edit +
                "/" +
                this.editedItem.id,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.loading = false;
              // console.log(response);
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.AddEditdialog = false;
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
              }
            });
        } else {
          axios
            .post(
              this.getApiUrl + "/" + this.endPoints.create,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              // console.log(response);
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.editedItem = {
                  id: "",
                  name: "",
                  percentage: "",
                  notes: "",
                  active: "",
                  countries: [],
                  en: {
                    name: "",
                  },
                  ar: {
                    name: "",
                  },
                };
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
                this.close();
              }

              this.loading = false;
            });
        }
      }
    },

    getAllCountries() {
      axios
        .get(this.getApiUrl + "/taxes/getAllCountries", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            locale:
              this.currentAppLocale != undefined ? this.currentAppLocale : "en",
          },
        })
        .then((response) => {
          // console.log(response);
          this.countries = response.data.data;
        });
    },

    toggle() {
      this.$nextTick(() => {
        if (this.selectAllCountries) {
          this.editedItem.countries = [];
        } else {
          // this.editedItem.countries = this.countries.slice();

          let all = this.countries;
          let selected = [];
          // console.log(all);
          all.forEach((country) => {
            // this.editedItem.countries = country.country_id;
            selected.push(country.country_id);
          });
          this.editedItem.countries = selected;
        }
      });
    },
  },
  mounted() {
    axios
      .get(this.getApiUrl + "/itemsCategories?select=yes", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.categories = response.data.data;
      });

    this.getAllCountries();
    // console.log(this.editedItem.countries);
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
@import "../../styles/_forms.scss";
</style>
