<template>
  <v-dialog v-model="AddEditdialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(formTitle) }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" class="mt-5">
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <label>{{ $t("Name") }}</label>

                <vue-tags-input
                  v-model="tag"
                  :tags="editedItem.name_arr"
                  :autocomplete-items="filteredItems"
                  placeholder
                  :add-on-blur="true"
                  :max-tags="1"
                  @tags-changed="newTags => (editedItem.name_arr = newTags)"
                />
                <div v-if="editedItem.name_arr.length == 0 && submitted">
                  <p class="red--text">{{ $t("This field is required") }}</p>
                </div>
                <div
                  v-if="
                    editedItem.name_arr.length > 0 &&
                      editedItem.name_arr[0].text.length > 50
                  "
                >
                  <p class="red--text">
                    {{
                      $t(
                        "You have reached your maximum limit of characters allowed"
                      )
                    }}
                  </p>
                </div>
                <div v-if="validation_errors.name_arr">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.name_arr"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <label>{{ $t("Author") }}</label>
                <v-text-field
                  v-model.trim="editedItem.author"
                  solo
                  @focus="onFocus()"
                ></v-text-field>
                <div v-if="validation_errors.author">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.author"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <label>{{ $t("Semester") }}</label>
                <v-select
                  :items="semesters"
                  item-text="name"
                  item-value="id"
                  v-model.trim="editedItem.semester"
                  :rules="[validationRules.required]"
                  @focus="onFocus()"
                  solo
                ></v-select>
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <div>
                  <label>{{ $t("Subject Assigned") }}</label>
                  <v-select
                    :items="subjects"
                    item-text="name"
                    item-value="id"
                    v-model.trim="editedItem.subject_id"
                    :rules="[validationRules.required]"
                    @focus="onFocus()"
                    solo
                  ></v-select>
                </div>
                <div v-if="validation_errors.subject_id">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.subject_id"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel cancelBtn" @click="close">{{
          $t("Close")
        }}</v-btn>
        <v-btn
          class="modal-btn-save saveBtn"
          @click="save"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  props: [
    "editedItem",
    "AddEditdialog",
    "formTitle",
    "editedIndex",
    "endPoints",
    "academic_grade_id"
  ],
  mixins: [validationMixin],
  name: "AddEditBooksComponent",
  components: {
    VueTagsInput
  },
  data() {
    return {
      name: [],
      tag: "",
      semesters: [],
      valid: true,
      validation_errors: {
        name: ""
      },
      loading: false,
      subjects: [],
      items: "",
      autocompleteItems: [],
      validation: [
        {
          classes: "min-length",
          rule: tag => tag.text.length < 8
        },
        {
          classes: "no-numbers",
          rule: /^([^0-9]*)$/
        },
        {
          classes: "avoid-item",
          rule: /^(?!Cannot).*$/,
          disableAdd: true
        },
        {
          classes: "no-braces",
          rule: ({ text }) =>
            text.indexOf("{") !== -1 || text.indexOf("}") !== -1
        }
      ],
      submitted: false
    };
  },
  watch: {
    AddEditdialog: {
      handler() {
        // this.editedItem.name_arr = [];
        if (this.editedIndex == -1) {
          this.editedItem.name_arr = [];
        }
        if (this.$refs.form) {
          this.resetValidation();
        }
      }
    }
  },
  computed: {
    filteredItems() {
      // console.log(this.tag);
      return this.autocompleteItems.filter(i => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    }
  },
  methods: {
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onFocus() {
      this.validation_errors = {
        name: ""
      };
    },
    close() {
      this.AddEditdialog = false;
      this.validation_errors = {
        name: "",
        subject_id: ""
      };
      this.editedItem = {};
      this.$emit("childToParent", false, null, this.editedIndex);
    },
    getitems() {
      axios
        .get(this.getApiUrl + "/school/getItems", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          console.log(response.data.data);
          this.autocompleteItems = response.data.data;
        });
    },
    getSemesters() {
      axios
        .get(this.getApiUrl + "/school/getSemesters", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          // console.log(response.data.data);
          this.semesters = response.data.data;
        });
    },

    save() {
      this.submitted = true;
      if (this.editedItem.name_arr.length != 0) {
        if (this.editedItem.name_arr[0].text.length > 50) {
          this.valid = false;
        } else {
          this.valid = true;
        }
      } else {
        this.valid = false;
      }
      if (this.valid == false) {
        this.validate();
      } else {
        this.loading = true;
        this.editedItem.academic_grade_id = this.$route.params.grade_year_id;
        if (this.editedIndex > -1) {
          axios
            .post(
              this.getApiUrl +
                "/" +
                this.endPoints.edit +
                "/" +
                this.editedItem.id,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token
                }
              }
            )
            .then(response => {
              this.loading = false;
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.AddEditdialog = false;
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
                location.reload();
              }
            });
        } else {
          axios
            .post(
              this.getApiUrl +
                "/" +
                this.endPoints.create +
                "/" +
                this.academic_grade_id,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token
                }
              }
            )
            .then(response => {
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.editedItem = {
                  name: "",
                  author: "",
                  academic_grade_id: "",
                  subject_id: ""
                };
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
                this.close();
              }
              location.reload();

              this.loading = false;
            });
        }
      }
    }
  },
  mounted() {
    this.academic_grade_id = this.$route.params.grade_year_id;
    this.getitems();
    this.getSemesters();
    axios
      .get(this.getApiUrl + "/school/showSubjects/" + this.academic_grade_id, {
        headers: {
          Authorization: "Bearer " + localStorage.token
        }
      })
      .then(response => {
        this.subjects = response.data.data.subjectsArrayNameId;
      });
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

.vue-tags-input .ti-input {
  padding: 4px 10px;
  transition: border-bottom 200ms ease;
  height: 48px !important;
}
</style>
