<template>
  <v-dialog v-model="AddEditdialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(formTitle) }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form v-model="valid" ref="form" class="mt-5">
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <label>{{ $t("Class Name") }} </label>
                <v-text-field
                  v-model.trim="editedItem.en.name"
                  solo
                  @focus="onFocus()"
                  :rules="ClassRule"
                ></v-text-field>
                <div v-if="validation_errors.en.name">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.en.name"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <label>{{ $t("Arabic Name") }} </label>
                <v-text-field
                  v-model.trim="editedItem.ar.name"
                  solo
                  @focus="onFocus()"
                  :rules="[CheckArabicCharactersOnly(editedItem.ar.name)]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Class Capacity") }}</label>
                <v-text-field
                  min="1"
                  v-on:keydown.109.capture.prevent.stop
                  v-on:keydown.189.capture.prevent.stop
                  v-model.trim="editedItem.students_count"
                  solo
                  @focus="onFocus()"
                  :rules="ClassCapacityRule"
                ></v-text-field>
                <div v-if="validation_errors.students_count">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.students_count"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Head Teacher") }}</label>
                <v-select
                  :items="teachers"
                  item-text="name"
                  item-value="id"
                  v-model.trim="editedItem.teacher_id"
                  @focus="onFocus()"
                  solo
                >
                </v-select>
                <!-- <select
                class="custom-select"
                id="teacher_id"
                name="teacher_id"
                v-model.trim="editedItem.teacher_id"
                @focus="onFocus()"
              >
                <option value=""></option>
                <option
                  v-for="(teacher, index) in teachers"
                  :key="index"
                  :value="teacher.id"
                  :selected="teacher.id == editedItem.teacher_id"
                  >{{ teacher.name }}</option
                >
              </select> -->
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel cancelBtn" @click="close">{{
          $t("Close")
        }}</v-btn>
        <v-btn
          class="modal-btn-save saveBtn"
          @click="save"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: [
    "editedItem",
    "AddEditdialog",
    "formTitle",
    "editedIndex",
    "endPoints",
    "academic_grade_id"
  ],
  name: "AddEditClassesComponents",
  mixins: [validationMixin],
  data() {
    return {
      ClassRule: [v => !!v || this.$i18n.t("This field is required")],
      ClassCapacityRule: [
        v => !!v || this.$i18n.t("This field is required"),
        v =>
          /^(?:[1-9]\d*|0)$/.test(v) ||
          "Please enter numbers only and not started with 0.",
        v => v != 0 || "Please enter numbers only and not started with 0."
      ],
      valid: false,
      validation_errors: {
        en: {
          name: ""
        }
      },
      loading: false,
      teachers: []
    };
  },
  watch: {
    AddEditdialog: {
      handler() {
        if (this.$refs.form) {
          this.resetValidation();
        }
      }
    }
  },
  computed: {
    propEditedItem: {
      get: function() {
        return this.editedItem;
      },
      set: function(value) {
        this.editedItem = value;
        return this.editedItem;
      }
    }
  },

  methods: {
    onFocus() {
      this.validation_errors = {
        en: {
          name: ""
        }
      };
    },
    close() {
      this.AddEditdialog = false;
      this.validation_errors = {
        en: {
          name: ""
        }
      };
      this.editedItem = {};
      this.$emit("childToParent", false, null, this.editedIndex);
    },

    save() {
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        this.loading = true;
        if (this.editedIndex > -1) {
          axios
            .post(
              this.getApiUrl +
                "/" +
                this.endPoints.edit +
                "/" +
                this.editedItem.id,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token
                }
              }
            )
            .then(response => {
              this.loading = false;
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.AddEditdialog = false;
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
              }
            });
        } else {
          axios
            .post(
              this.getApiUrl +
                "/" +
                this.endPoints.create +
                "/" +
                this.academic_grade_id,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token
                }
              }
            )
            .then(response => {
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.editedItem = {
                  en: {
                    name: ""
                  },
                  ar: {
                    name: ""
                  },
                  name: "",
                  code: "",
                  students_count: "",
                  teacher_id: ""
                };
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
                this.close();
              }

              this.loading = false;
            });
        }
      }
    }
  },
  mounted() {
    axios
      .get(this.getApiUrl + "/users?role=Teacher", {
        headers: {
          Authorization: "Bearer " + localStorage.token
        }
      })
      .then(response => {
        this.teachers = response.data.data;
      });
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
</style>
