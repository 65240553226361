<template>
  <v-dialog v-model="AddEditdialog" persistent max-width="850px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline"> {{ $t(formTitle) }} </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" class="mt-5">
            <v-row>
              <v-col sm="12" md="4" xs="12" cols="12">
                <label
                  :class="currentAppLocale == 'ar' ? 'text-right d-block' : ''"
                  for="firstName"
                  >{{ $t("First Name") }}</label
                >
                <v-text-field
                  name="firstName"
                  v-model.trim="editedItem.first_name"
                  @focus="onFocus()"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength50,
                  ]"
                  solo
                ></v-text-field>
                <div v-if="validation_errors.first_name">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.first_name"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col sm="12" md="4" xs="12" cols="12">
                <label
                  :class="currentAppLocale == 'ar' ? 'text-right d-block' : ''"
                  for="lastName"
                  >{{ $t("Last Name") }}</label
                >
                <v-text-field
                  name="lastName"
                  v-model.trim="editedItem.last_name"
                  @focus="onFocus()"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength50,
                  ]"
                  solo
                ></v-text-field>
                <div v-if="validation_errors.last_name">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.last_name"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <!-- ar name -->
              <v-col sm="12" md="4" xs="12" cols="12">
                <label
                  :class="currentAppLocale == 'ar' ? 'text-right d-block' : ''"
                  for="arabicName"
                  >{{ $t("Arabic Name") }}</label
                >
                <v-text-field
                  name="arabicName"
                  v-model="editedItem.ar_name"
                  :rules="[
                    validationRules.maxLength50,
                    CheckArabicCharactersOnly(editedItem.ar_name),
                  ]"
                  solo
                  @focus="onFocus()"
                ></v-text-field>
                <div v-if="validation_errors.ar_name">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.ar_name"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <!-- ar name -->

              <v-col sm="12" md="6" xs="12" cols="12">
                <label
                  :class="currentAppLocale == 'ar' ? 'text-right d-block' : ''"
                  for="nationalities"
                  >{{ $t("Country") }}</label
                >
                <v-autocomplete
                  name="nationalities"
                  :items="nationalities"
                  @change="hideLabel = true"
                  item-text="name"
                  item-value="id"
                  v-model="editedItem.country_id"
                  :rules="[validationRules.required]"
                  solo
                ></v-autocomplete>
                <div v-if="validation_errors.country_id">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.country_id"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <v-col cols="5" sm="4" md="2" class="mobile-code pr-0">
                <!-- <div id="country-code"> -->
                <label
                  :class="currentAppLocale == 'ar' ? 'text-right d-block' : ''"
                  for="Code"
                  >{{ $t("Code") }}</label
                >
                <v-autocomplete
                  name="Code"
                  :items="Codes"
                  required
                  @change="hideLabel = true"
                  item-text="title"
                  item-value="value"
                  v-model="editedItem.country_code_id"
                  solo
                >
                  <template slot="selection" slot-scope="data">
                    <!-- <img src="../../assets/flags/AF.png" />
                    {{ data.title }}-->

                    <v-flex xs3>
                      <v-avatar size="25">
                        <img :src="data.item.icon" />
                      </v-avatar>
                    </v-flex>
                    <v-flex class="ml-3">{{ data.item.title }}</v-flex>
                  </template>
                  <template slot="item" slot-scope="data">
                    <v-list-item-avatar
                      style="width: 20px; min-width: 20px; height: 20px"
                    >
                      <img :src="data.item.icon" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.title"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <!-- </div> -->

                <div v-if="validation_errors.country_code_id">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.country_code_id"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col cols="7" sm="8" md="4">
                <label
                  :class="currentAppLocale == 'ar' ? 'text-right d-block' : ''"
                  for="Mobile"
                  >{{ $t("Mobile") }}</label
                >
                <v-text-field
                  name="Mobile"
                  v-model.trim="editedItem.mobile"
                  @focus="onFocus()"
                  :rules="[
                    validationRules.required,
                    mobileRule(editedItem.country_code_id, editedItem.mobile),
                  ]"
                  solo
                ></v-text-field>
                <div v-if="validation_errors.mobile">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.mobile"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col sm="12" md="6" xs="12" cols="12">
                <label
                  :class="currentAppLocale == 'ar' ? 'text-right d-block' : ''"
                  for="Email"
                  >{{ $t("Email") }}</label
                >
                <v-text-field
                  name="email"
                  v-model.trim="editedItem.email"
                  @focus="onFocus()"
                  :rules="[validationRules.required, validationRules.email]"
                  solo
                ></v-text-field>
                <div v-if="validation_errors.email">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.email"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <v-col
                cols="8"
                sm="8"
                md="4"
                xs="8"
                v-if="formTitle == 'Add User'"
                class="space"
              >
                <label
                  :class="currentAppLocale == 'ar' ? 'text-right d-block' : ''"
                  for="password"
                  >{{ $t("Password") }}</label
                >
                <v-text-field
                  v-model.trim="editedItem.password"
                  name="password"
                  @focus="onFocus()"
                  id="testing-code"
                  :type="passwordType"
                  :rules="[validationRules.required]"
                  solo
                ></v-text-field>
                <div v-if="validation_errors.password">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.password"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-row class="passwordIcons" v-if="formTitle == 'Add User'">
                <v-col sm="2" md="1" xs="2" cols="2">
                  <v-icon @click="copyTestingCode">file_copy</v-icon>
                </v-col>
                <v-col sm="2" md="1" xs="2" cols="2">
                  <v-icon
                    class="iconpassword"
                    @click="showPassword"
                    v-if="passwordType == 'password'"
                    >visibility_off</v-icon
                  >
                  <v-icon
                    class="iconpassword"
                    @click="showPassword"
                    v-if="passwordType == 'text'"
                    >visibility</v-icon
                  >
                </v-col>
              </v-row>

              <v-col sm="6" md="3" xs="6" cols="6">
                <label
                  :class="currentAppLocale == 'ar' ? 'text-right d-block' : ''"
                  for="dateOfBirth"
                  >{{ $t("Date Of Birth") }}</label
                >
                <v-text-field
                  id="gregorian"
                  name="dateOfBirth"
                  @focus="onFocus()"
                  autocomplete="off"
                  v-model.lazy="editedItem.date_of_birth"
                  solo
                  @keydown.prevent
                  @paste.prevent
                  @drop.prevent
                ></v-text-field>
                <div
                  v-if="validation_errors.date_of_birth"
                  class="vertical-space"
                >
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.date_of_birth"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col sm="6" md="3" xs="6" cols="6">
                <label
                  :class="currentAppLocale == 'ar' ? 'text-right d-block' : ''"
                  >{{ $t("Gender") }}</label
                >
                <div>
                  <v-row justify="start" class="mt-1">
                    <v-checkbox
                      class="mx-2"
                      :label="$t('male')"
                      id="male"
                      value="male"
                      v-model.trim="editedItem.gender"
                      @change="onFocus()"
                    ></v-checkbox>
                    <v-checkbox
                      class="mx-2"
                      :label="$t('female')"
                      id="female"
                      value="female"
                      v-model.trim="editedItem.gender"
                      @change="onFocus()"
                    ></v-checkbox>
                  </v-row>
                </div>
                <div v-if="validation_errors.gender">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.gender"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col
                sm="12"
                md="6"
                v-if="
                  editUserRolePermission &&
                  
                  !editedItem.roles_ids.includes(4)
                "
                xs="12"
                cols="12"
              >
                <label
                  :class="currentAppLocale == 'ar' ? 'text-right d-block' : ''"
                  >{{ $t("Role") }}</label
                >
                <v-autocomplete
                  :items="roles"
                  @change="hideLabel = true"
                  item-text="display_name"
                  item-value="id"
                  multiple
                  v-model="editedItem.roles_ids"
                  :rules="[checkMultiSelect(editedItem.roles_ids)]"
                  solo
                ></v-autocomplete>
                <div v-if="validation_errors.roles_ids">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.roles_ids"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <!-- !editedItem.roles_ids.includes(3) &&
                  !editedItem.roles_ids.includes(4) &&
                  !editedItem.roles_ids.includes(5) -->
              <v-col
                sm="12"
                md="6"
                xs="12"
                cols="12"
                v-if="
                  shoudShowGrades
                "
              >
                <label
                  :class="currentAppLocale == 'ar' ? 'text-right d-block' : ''"
                  >{{ $t("Grades") }}</label
                >
                <v-autocomplete
                  :items="grades"
                  @change="hideLabel = true"
                  item-text="grade_name"
                  item-value="id"
                  v-model="editedItem.grade_ids"
                  :rules="[checkMultiSelect(editedItem.grade_ids)]"
                  solo
                  multiple
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel" @click="close">{{ $t("Close") }}</v-btn>
        <v-btn
          class="modal-btn-save"
          @click="save"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import ACL from "../../acl";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: [
    "editedItem",
    "AddEditdialog",
    "formTitle",
    "editedIndex",
    "endPoints",
  ],
  name: "AddEditUserComponent",
  mixins: [validationMixin],
  data() {
    return {
      validation_errors: {
        first_name: "",
        last_name: "",
        ar_name: "",
        country_id: "",
        country_code_id: "",
        mobile: "",
        email: "",
        password: "",
        date_of_birth: "",
        gender: "",
        roles_ids: "",
      },
      loading: false,
      categories: [],
      passwordType: "password",
      valid: false,
      value: true,
      Codes: [],
      nationalities: [],
      showPasswordIcon: "visibility_off",
      roles: [],
      grades: [],
      editUserRolePermission: ACL.checkPermission("edit-users-role"),
    };
  },
  watch: {
    AddEditdialog: {
      handler() {
        if (this.$refs.form) {
          this.resetValidation();
        }
      },
    },
  },

  methods: {
    copyTestingCode() {
      let testingCodeToCopy = document.querySelector("#testing-code");
      testingCodeToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
      testingCodeToCopy.select();
      document.execCommand("copy");
      /* unselect the range */
      testingCodeToCopy.setAttribute("type", this.passwordType);
      window.getSelection().removeAllRanges();
    },
    showPassword() {
      if (this.passwordType == "text") {
        this.passwordType = "password";
      } else {
        this.passwordType = "text";
      }
    },
    onFocus() {
      this.validation_errors = {
        first_name: "",
        last_name: "",
        ar_name: "",
        email: "",
        password: "",
        mobile: "",
        date_of_birth: "",
        country_id: "",
        roles_ids: "",
        country_code_id: "",
      };
    },
    close() {
      this.AddEditdialog = false;
      this.validation_errors = {
        first_name: "",
        last_name: "",
        ar_name: "",
        email: "",
        password: "",
        mobile: "",
        date_of_birth: "",
        country_id: "",
        roles_ids: "",
        country_code_id: "",
      };
      this.editedItem = {
        first_name: "",
        last_name: "",
        ar_name: "",
        email: "",
        password: "",
        mobile: "",
        date_of_birth: "",
        country_id: 187,
        roles_ids: [],
        grade_ids: [],
        country_code_id: 187,
      };
      this.passwordType = "password";
      this.$emit("childToParent", false, null, this.editedIndex);
    },

    save() {
      if (this.valid == false) {
        this.validate();
      } else {
        this.loading = true;
        if (this.editedIndex > -1) {
          axios
            .post(
              this.getApiUrl +
                "/" +
                this.endPoints.edit +
                "/" +
                this.editedItem.id,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.loading = false;
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.AddEditdialog = false;
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
              }
            });
        } else {
          axios
            .post(
              this.getApiUrl + "/" + this.endPoints.create,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.editedItem = {
                  first_name: "",
                  last_name: "",
                  ar_name: "",
                  email: "",
                  password: "",
                  mobile: "",
                  date_of_birth: "",
                  country_id: 187,
                  roles_ids: [],
                  grade_ids: [],
                  country_code_id: 187,
                };
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
                this.close();
              }

              this.loading = false;
            });
        }
      }
    },
    getAllGrades() {
      axios
        .get(this.getApiUrl + "/users/getGrades", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.grades = response.data.data;
        });
    },
    getCountryCodes() {
      axios
        .get(this.getApiUrl + "/getCodes", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.Codes = response.data.data;
        });
    },
  },
  mounted() {
    this.getAllGrades();
    axios
      .get(this.getApiUrl + "/itemsCategories?select=yes", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.categories = response.data.data;
      });

    this.getCountryCodes();
    axios.get(this.getApiUrl + "/getCountries").then((response) => {
      this.nationalities = response.data.data;
    });

    axios.get(this.getApiUrl + "/allRoles").then((response) => {
      this.roles = response.data.data;
    });
  },
  computed:{
    shoudShowGrades(){
      var arr2 =[1,2,6,7,8,11];
      return this.editedItem.roles_ids.some(item => arr2.includes(item))
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

.checkbox {
  margin-top: -25px;
  margin-left: 4px;
}
.passwordIcons {
  margin-top: 50px;
}
.iconpassword {
  border: 1px solid white;
  box-shadow: gray;
}
</style>
