<template>
  <div id="printExamResultsModal">
    <v-dialog
      v-model="printDialog"
      persistent
      max-width="600px"
      class="printExamsDialog"
      scrollable
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row class="space">
              <v-col cols="12" sm="12" md="12">
                <v-tabs
                  v-model="tab"
                  background-color="primary"
                  dark
                  id="printTabsHeaders"
                >
                  <v-tab v-if="GradeSheetpermission">
                    <v-row>
                      <v-col cols="12" md="12">
                        <div class="imageDiv text-center">
                          <img
                            src="../../assets/icons/final-results.png"
                            alt=""
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" md="12">
                        <div class="content main-color">
                          {{ $t("Final Results") }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-tab>
                  <v-tab class="text-center">
                    <v-row>
                      <v-col cols="12" md="12">
                        <div class="imageDiv text-center">
                          <img
                            src="../../assets/icons/subjects-colored.png"
                            alt=""
                          />
                        </div>
                      </v-col>
                      <v-col cols="12" md="12">
                        <div class="content main-color">
                          {{ $t("All Subjects") }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-tab>
                  <v-tab>
                    <v-row>
                      <v-col cols="12" md="12">
                        <div class="imageDiv text-center">
                          <img src="../../assets/icons/subject.png" alt="" />
                        </div>
                      </v-col>
                      <v-col cols="12" md="12">
                        <div class="content main-color">
                          {{ $t("Subject") }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-tab>
                  <v-tab>
                    <v-row>
                      <v-col cols="12" md="12">
                        <div class="imageDiv text-center">
                          <img src="../../assets/icons/qualifier.png" alt="" />
                        </div>
                      </v-col>
                      <v-col cols="12" md="12">
                        <div class="content main-color">
                          {{ $t("Qualifier") }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-tab>
                </v-tabs>

                <v-card id="printTabsCard">
                  <v-tabs-items v-model="tab" id="printTabsItems">
                    <v-tab-item v-if="GradeSheetpermission">
                      <v-form ref="form" v-model="valid">
                        <v-row>
                          <v-radio-group v-model="allSubjects.period_type">
                            <v-radio
                              label="Current Academic Year"
                              value="1"
                            ></v-radio>
                            <!-- <v-radio
                              label="Academic Period"
                              value="2"
                            ></v-radio>
                            <v-row>
                              <v-col
                                cols="10"
                                md="10"
                                offset="1"
                                class="innerDiv"
                                :class="{
                                  hidden: allSubjects.period_type != 2
                                }"
                              >
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <label for="From">Semester</label>
                                    <v-select
                                      v-model="
                                        allSubjects.academic_semester_ids
                                      "
                                      :items="semesters"
                                      item-text="name"
                                      item-value="id"
                                      :rules="[
                                        semestersRequireRule(
                                          allSubjects.academic_semester_ids
                                        )
                                      ]"
                                      chips
                                      multiple
                                      solo
                                    >
                                      <template
                                        v-slot:selection="{ item, index }"
                                      >
                                        <v-chip v-if="index < 1">
                                          <span>{{ item.name }}</span>
                                        </v-chip>
                                        <span
                                          v-if="index == 1"
                                          class="grey--text caption"
                                          >(+{{
                                            allSubjects.academic_semester_ids
                                              .length - 1
                                          }}
                                          others)</span
                                        >
                                      </template>
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <label for="From">Quarter</label>
                                    <v-select
                                      v-model="
                                        allSubjects.academic_quarters_ids
                                      "
                                      :items="quarters"
                                      item-text="name"
                                      item-value="id"
                                      chips
                                      multiple
                                      solo
                                    >
                                      <template
                                        v-slot:selection="{ item, index }"
                                      >
                                        <v-chip v-if="index < 1">
                                          <span>{{ item.name }}</span>
                                        </v-chip>
                                        <span
                                          v-if="index == 1"
                                          class="grey--text caption"
                                          >(+{{
                                            allSubjects.academic_quarters_ids
                                              .length - 1
                                          }}
                                          others)</span
                                        >
                                      </template>
                                    </v-select>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>

                            <v-radio
                              label="Specific Period"
                              value="3"
                            ></v-radio> -->
                          </v-radio-group>

                          <v-col
                            cols="10"
                            md="10"
                            offset="1"
                            class="innerDiv"
                            :class="{ hidden: allSubjects.period_type != 3 }"
                          >
                            <v-row>
                              <v-col cols="12" md="6">
                                <label for="From">{{ $t("From") }}</label>
                                <v-text-field
                                  v-model="allSubjects.from"
                                  append-icon="calendar_today"
                                  id="from"
                                  autocomplete="off"
                                  :rules="[
                                    startDateRule(
                                      allSubjects.from,
                                      allSubjects.to
                                    ),
                                  ]"
                                  solo
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <label for="From">{{ $t("To") }}</label>
                                <v-text-field
                                  v-model="allSubjects.to"
                                  append-icon="calendar_today"
                                  id="to"
                                  autocomplete="off"
                                  :rules="[
                                    startDateRule(
                                      allSubjects.from,
                                      allSubjects.to
                                    ),
                                  ]"
                                  solo
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12">
                            <label for="subject">{{ $t("Students") }}</label>

                            <v-select
                              v-model="allSubjects.students_ids"
                              :items="students"
                              item-text="student_full_name"
                              item-value="id"
                              multiple
                              chips
                              solo
                            >
                              <template v-slot:prepend-item>
                                <v-list-item ripple @click="toggle">
                                  <v-list-item-action>
                                    <v-icon
                                      :color="
                                        allSubjects.students_ids.length > 0
                                          ? 'indigo darken-4'
                                          : ''
                                      "
                                      >{{ icon }}</v-icon
                                    >
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>{{
                                      $t("Select All")
                                    }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                              </template>

                              <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index < 2">
                                  <span>{{ item.student_full_name }}</span>
                                </v-chip>
                                <span
                                  v-if="index == 2"
                                  class="grey--text caption"
                                  >(+{{ allSubjects.students_ids.length - 2 }}
                                  {{ $t("others") }})</span
                                >
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-tab-item>
                    <v-tab-item>
                      <v-form ref="form" v-model="valid">
                        <v-row>
                          <v-radio-group v-model="allSubjects.period_type">
                            <v-radio
                              :label="$t('Current Academic Year')"
                              value="1"
                            ></v-radio>
                            <v-radio
                              :label="$t('Academic Period')"
                              value="2"
                            ></v-radio>
                            <v-row>
                              <v-col
                                cols="10"
                                md="10"
                                offset="1"
                                class="innerDiv"
                                :class="{
                                  hidden: allSubjects.period_type != 2,
                                }"
                              >
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <label for="From">{{
                                      $t("Semester")
                                    }}</label>
                                    <v-select
                                      v-model="
                                        allSubjects.academic_semester_ids
                                      "
                                      :items="semesters"
                                      item-text="name"
                                      item-value="id"
                                      :rules="[
                                        semestersRequireRule(
                                          allSubjects.academic_semester_ids
                                        ),
                                      ]"
                                      chips
                                      multiple
                                      solo
                                    >
                                      <template
                                        v-slot:selection="{ item, index }"
                                      >
                                        <v-chip v-if="index < 1">
                                          <span>{{ item.name }}</span>
                                        </v-chip>
                                        <span
                                          v-if="index == 1"
                                          class="grey--text caption"
                                          >(+{{
                                            allSubjects.academic_semester_ids
                                              .length - 1
                                          }}
                                          others)</span
                                        >
                                      </template>
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <label for="From">{{
                                      $t("Quarter")
                                    }}</label>
                                    <v-select
                                      v-model="
                                        allSubjects.academic_quarters_ids
                                      "
                                      :items="quarters"
                                      item-text="name"
                                      item-value="id"
                                      chips
                                      multiple
                                      solo
                                    >
                                      <template
                                        v-slot:selection="{ item, index }"
                                      >
                                        <v-chip v-if="index < 1">
                                          <span>{{ item.name }}</span>
                                        </v-chip>
                                        <span
                                          v-if="index == 1"
                                          class="grey--text caption"
                                          >(+{{
                                            allSubjects.academic_quarters_ids
                                              .length - 1
                                          }}
                                          {{ $t("others") }})</span
                                        >
                                      </template>
                                    </v-select>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>

                            <v-radio
                              :label="$t('Specific Period')"
                              value="3"
                            ></v-radio>
                          </v-radio-group>

                          <v-col
                            cols="10"
                            md="10"
                            offset="1"
                            class="innerDiv"
                            :class="{ hidden: allSubjects.period_type != 3 }"
                          >
                            <v-row>
                              <v-col cols="12" md="6">
                                <label for="From">{{ $t("From") }}</label>
                                <v-text-field
                                  v-model="allSubjects.from"
                                  append-icon="calendar_today"
                                  id="from"
                                  autocomplete="off"
                                  :rules="[
                                    startDateRule(
                                      allSubjects.from,
                                      allSubjects.to
                                    ),
                                  ]"
                                  solo
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <label for="From">{{ $t("To") }}</label>
                                <v-text-field
                                  v-model="allSubjects.to"
                                  append-icon="calendar_today"
                                  id="to"
                                  autocomplete="off"
                                  :rules="[
                                    startDateRule(
                                      allSubjects.from,
                                      allSubjects.to
                                    ),
                                  ]"
                                  solo
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12">
                            <label for="subject">{{ $t("Students") }}</label>

                            <v-select
                              v-model="allSubjects.students_ids"
                              :items="students"
                              item-text="student_full_name"
                              item-value="id"
                              multiple
                              chips
                              solo
                            >
                              <template v-slot:prepend-item>
                                <v-list-item ripple @click="toggle">
                                  <v-list-item-action>
                                    <v-icon
                                      :color="
                                        allSubjects.students_ids.length > 0
                                          ? 'indigo darken-4'
                                          : ''
                                      "
                                      >{{ icon }}</v-icon
                                    >
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>{{
                                      $t("Select All")
                                    }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                              </template>

                              <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index < 2">
                                  <span>{{ item.student_full_name }}</span>
                                </v-chip>
                                <span
                                  v-if="index == 2"
                                  class="grey--text caption"
                                  >(+{{ allSubjects.students_ids.length - 2 }}
                                  {{ $t("others") }})</span
                                >
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-tab-item>
                    <v-tab-item>
                      <v-form ref="subjectForm" v-model="subjectFormValid">
                        <v-row>
                          <v-col cols="12" md="12">
                            <label for="subject">{{ $t("Subject") }}</label>
                            <v-select
                              :items="subjects"
                              item-text="name"
                              item-value="id"
                              v-model="subject.subject_ids"
                              :rules="[
                                subjectRequiredRule(subject.subject_ids),
                              ]"
                              multiple
                              chips
                              solo
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row class="radio-section">
                          <v-radio-group v-model="subject.period_type">
                            <v-radio
                              :label="$t('Current Academic Year')"
                              value="1"
                            ></v-radio>
                            <v-radio
                              :label="$t('Academic Period')"
                              value="2"
                            ></v-radio>
                            <v-row>
                              <v-col
                                cols="10"
                                md="10"
                                offset="1"
                                class="innerDiv"
                                :class="{
                                  hidden: subject.period_type != 2,
                                }"
                              >
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <label for="From">{{
                                      $t("Semester")
                                    }}</label>
                                    <v-select
                                      v-model="subject.academic_semester_ids"
                                      :items="semesters"
                                      item-text="name"
                                      item-value="id"
                                      :rules="[
                                        semestersRequireRule(
                                          subject.academic_semester_ids
                                        ),
                                      ]"
                                      chips
                                      multiple
                                      solo
                                    >
                                      <template
                                        v-slot:selection="{ item, index }"
                                      >
                                        <v-chip v-if="index < 1">
                                          <span>{{ item.name }}</span>
                                        </v-chip>
                                        <span
                                          v-if="index == 1"
                                          class="grey--text caption"
                                          >(+{{
                                            subject.academic_semester_ids
                                              .length - 1
                                          }}
                                          {{ $t("others") }})</span
                                        >
                                      </template>
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <label for="From">{{
                                      $t("Quarter")
                                    }}</label>
                                    <v-select
                                      v-model="subject.academic_quarters_ids"
                                      :items="quarters"
                                      item-text="name"
                                      item-value="id"
                                      chips
                                      multiple
                                      solo
                                    >
                                      <template
                                        v-slot:selection="{ item, index }"
                                      >
                                        <v-chip v-if="index < 1">
                                          <span>{{ item.name }}</span>
                                        </v-chip>
                                        <span
                                          v-if="index == 1"
                                          class="grey--text caption"
                                          >(+{{
                                            subject.academic_quarters_ids
                                              .length - 1
                                          }}
                                          {{ $t("others") }})</span
                                        >
                                      </template>
                                    </v-select>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>

                            <v-radio
                              :label="$t('Specific Period')"
                              value="3"
                            ></v-radio>
                          </v-radio-group>

                          <v-col
                            cols="10"
                            md="10"
                            offset="1"
                            class="innerDiv"
                            :class="{ hidden: subject.period_type != 3 }"
                          >
                            <v-row>
                              <v-col cols="12" md="6">
                                <label for="From">{{ $t("From") }}</label>
                                <v-text-field
                                  v-model="subject.from"
                                  append-icon="calendar_today"
                                  id="fromTab2"
                                  autocomplete="off"
                                  :rules="[
                                    startDateRule(subject.from, subject.to),
                                  ]"
                                  solo
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <label for="From">{{ $t("To") }}</label>
                                <v-text-field
                                  v-model="subject.to"
                                  append-icon="calendar_today"
                                  id="toTab2"
                                  autocomplete="off"
                                  :rules="[
                                    startDateRule(subject.from, subject.to),
                                  ]"
                                  solo
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12">
                            <label for="subject">{{ $t("Students") }}</label>

                            <v-select
                              v-model="subject.students_ids"
                              :items="students"
                              item-text="student_full_name"
                              item-value="id"
                              multiple
                              chips
                              solo
                            >
                              <template v-slot:prepend-item>
                                <v-list-item ripple @click="toggle">
                                  <v-list-item-action>
                                    <v-icon
                                      :color="
                                        subject.students_ids.length > 0
                                          ? 'indigo darken-4'
                                          : ''
                                      "
                                      >{{ icon }}</v-icon
                                    >
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>{{
                                      $t("Select All")
                                    }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                              </template>
                              <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index < 2">
                                  <span>{{ item.student_full_name }}</span>
                                </v-chip>
                                <span
                                  v-if="index == 2"
                                  class="grey--text caption"
                                  >(+{{ subject.students_ids.length - 2 }}
                                  {{ $t("others") }})</span
                                >
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-tab-item>
                    <v-tab-item>
                      <v-form ref="qualifierForm" v-model="qualifierFormValid">
                        <v-row>
                          <v-col cols="12" md="6">
                            <label for="subject">{{ $t("Subject") }}</label>
                            <v-select
                              :items="subjects"
                              item-text="name"
                              item-value="id"
                              v-model="qualifier.subject_ids"
                              :rules="[
                                subjectRequiredRule(qualifier.subject_ids),
                              ]"
                              multiple
                              chips
                              solo
                            >
                              <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index < 1">
                                  <span>{{ item.name }}</span>
                                </v-chip>
                                <span
                                  v-if="index == 1"
                                  class="grey--text caption"
                                  >(+{{ qualifier.subject_ids.length - 1 }}
                                  {{ $t("others") }})</span
                                >
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="12" md="6">
                            <label for="subject">{{ $t("Qualifier") }}</label>

                            <v-select
                              :items="qualifiers"
                              v-model="qualifier.qualifier_ids"
                              item-text="qualifier_name"
                              item-value="id"
                              multiple
                              chips
                              solo
                            >
                              <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index < 1">
                                  <span>{{ item.qualifier_name }}</span>
                                </v-chip>
                                <span
                                  v-if="index == 1"
                                  class="grey--text caption"
                                  >(+{{ qualifier.qualifier_ids.length - 1 }}
                                  {{ $t("others") }})</span
                                >
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-radio-group v-model="qualifier.period_type">
                            <v-radio
                              :label="$t('Current Academic Year')"
                              value="1"
                            ></v-radio>
                            <v-radio
                              :label="$t('Academic Period')"
                              value="2"
                            ></v-radio>
                            <v-row>
                              <v-col
                                cols="10"
                                md="10"
                                offset="1"
                                class="innerDiv"
                                :class="{
                                  hidden: qualifier.period_type != 2,
                                }"
                              >
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <label for="From">{{
                                      $t("Semester")
                                    }}</label>
                                    <v-select
                                      v-model="qualifier.academic_semester_ids"
                                      :items="semesters"
                                      item-text="name"
                                      item-value="id"
                                      :rules="[
                                        semestersRequireRule(
                                          qualifier.academic_semester_ids
                                        ),
                                      ]"
                                      chips
                                      multiple
                                      solo
                                    >
                                      <template
                                        v-slot:selection="{ item, index }"
                                      >
                                        <v-chip v-if="index < 1">
                                          <span>{{ item.name }}</span>
                                        </v-chip>
                                        <span
                                          v-if="index == 1"
                                          class="grey--text caption"
                                          >(+{{
                                            qualifier.academic_quarters_ids
                                              .length - 1
                                          }}
                                          {{ $t("others") }})</span
                                        >
                                      </template>
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <label for="From">{{
                                      $t("Quarter")
                                    }}</label>
                                    <v-select
                                      v-model="qualifier.academic_quarters_ids"
                                      :items="quarters"
                                      item-text="name"
                                      item-value="id"
                                      chips
                                      multiple
                                      solo
                                    >
                                      <template
                                        v-slot:selection="{ item, index }"
                                      >
                                        <v-chip v-if="index < 1">
                                          <span>{{ item.name }}</span>
                                        </v-chip>
                                        <span
                                          v-if="index == 1"
                                          class="grey--text caption"
                                          >(+{{
                                            qualifier.academic_quarters_ids
                                              .length - 1
                                          }}
                                          {{ $t("others") }})</span
                                        >
                                      </template>
                                    </v-select>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>

                            <v-radio
                              :label="$t('Specific Period')"
                              value="3"
                            ></v-radio>
                          </v-radio-group>

                          <v-col
                            cols="10"
                            md="10"
                            offset="1"
                            class="innerDiv"
                            :class="{ hidden: qualifier.period_type != 3 }"
                          >
                            <v-row>
                              <v-col cols="12" md="6">
                                <label for="From">{{ $t("From") }}</label>
                                <v-text-field
                                  v-model="qualifier.from"
                                  append-icon="calendar_today"
                                  id="fromTab3"
                                  autocomplete="off"
                                  :rules="[
                                    startDateRule(qualifier.from, qualifier.to),
                                  ]"
                                  solo
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <label for="From">{{ $t("To") }}</label>
                                <v-text-field
                                  v-model="qualifier.to"
                                  append-icon="calendar_today"
                                  id="toTab3"
                                  autocomplete="off"
                                  :rules="[
                                    startDateRule(qualifier.from, qualifier.to),
                                  ]"
                                  solo
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12">
                            <label for="subject">{{ $t("Students") }}</label>

                            <v-select
                              v-model="qualifier.students_ids"
                              :items="students"
                              item-text="student_full_name"
                              item-value="id"
                              multiple
                              chips
                              solo
                            >
                              <template v-slot:prepend-item>
                                <v-list-item ripple @click="toggle">
                                  <v-list-item-action>
                                    <v-icon
                                      :color="
                                        qualifier.students_ids.length > 0
                                          ? 'indigo darken-4'
                                          : ''
                                      "
                                      >{{ icon }}</v-icon
                                    >
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>{{
                                      $t("Select All")
                                    }}</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                              </template>
                              <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index < 2">
                                  <span>{{ item.student_full_name }}</span>
                                </v-chip>
                                <span
                                  v-if="index == 2"
                                  class="grey--text caption"
                                  >(+{{ qualifier.students_ids.length - 2 }}
                                  {{ $t("others") }})</span
                                >
                              </template>
                              <!-- <template
                                v-if="
                                  qualifier.students_ids.length ==
                                    students.length 
                                "
                                v-slot:selection="{ index }"
                              >
                                <v-chip v-if="index == students.length - 1">
                                  <span>All Students</span>
                                </v-chip>
                              </template> -->
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="space">
          <v-spacer></v-spacer>

          <v-btn class="modal-btn-cancel" @click="close">{{
            $t("Close")
          }}</v-btn>
          <v-btn class="modal-btn-save" @click="checkTab">{{
            $t("Print")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { validationMixin } from "../../mixins/validationMixin";
import ACL from "../../acl";
export default {
  props: ["editedItem", "printDialog", "editedIndex"],
  name: "PrintExamResultModal",
  mixins: [validationMixin],
  data() {
    return {
      GradeSheetpermission: ACL.checkPermission("grade-sheet"),
      valid: true,
      subjectFormValid: true,
      qualifierFormValid: true,
      tab: null,
      items: ["arabic", "english", "geo"],
      // tab1
      allSubjects: {
        period_type: "1",
        from: "",
        to: "",
        academic_semester_ids: [],
        academic_quarters_ids: [],
        students_ids: [],
      },
      // tab2
      subject: {
        subject_ids: [],
        period_type: "1",
        from: "",
        to: "",
        academic_semester_ids: [],
        academic_quarters_ids: [],
        students_ids: [],
      },
      // tab3
      qualifier: {
        subject_ids: [],
        qualifier_ids: [],
        period_type: "1",
        from: "",
        to: "",
        academic_semester_ids: [],
        academic_quarters_ids: [],
        students_ids: [],
      },
      subjects: [],
      qualifiers: [],
      semesters: [],
      quarters: [],
      students: [],

      academicId: "",
      classId: "",

      /* validation */
      subjectsRules: [(v) => !!v || "subject is required"],
    };
  },
  computed: {
    selectAllStudents() {
      return this.tab == 0 || this.tab == null
        ? this.allSubjects.students_ids.length === this.students.length
        : this.tab == 1
        ? this.allSubjects.students_ids.length === this.students.length
        : this.tab == 2
        ? this.subject.students_ids.length === this.students.length
        : this.qualifier.students_ids.length === this.students.length;
    },
    selectSomeStudents() {
      return this.tab == 0 || this.tab == null
        ? this.allSubjects.students_ids.length > 0 && !this.selectAllStudents
        : this.tab == 1
        ? this.allSubjects.students_ids.length > 0 && !this.selectAllStudents
        : this.tab == 2
        ? this.subject.students_ids.length > 0 && !this.selectAllStudents
        : this.qualifier.students_ids.length > 0 && !this.selectAllStudents;
    },
    icon() {
      if (this.selectAllStudents) return "mdi-close-box";
      if (this.selectSomeStudents) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    printDialog: {
      handler() {
        if (this.printDialog == true) {
          this.activateDatePicker();
          this.resetTabs();

          if (this.$refs.form) {
            this.$refs.form.resetValidation();
          }
          if (this.$refs.subjectForm) {
            this.$refs.subjectForm.resetValidation();
          }
          if (this.$refs.qualifierForm) {
            this.$refs.qualifierForm.resetValidation();
          }
        }
      },
    },

    // tab 1
    "allSubjects.period_type": {
      handler() {
        this.allSubjects.to = "";
        this.allSubjects.from = "";

        if (this.allSubjects.period_type == 1) {
          this.allSubjects.academic_semester_ids = [];
          this.allSubjects.academic_quarters_ids = [];
        }

        if (this.allSubjects.period_type == 2) {
          this.getSemesters();
        }

        if (this.allSubjects.period_type == 3) {
          this.allSubjects.academic_semester_ids = [];
          this.allSubjects.academic_quarters_ids = [];
        }
      },
    },
    "allSubjects.academic_semester_ids": {
      handler() {
        this.getQuarters();
      },
    },

    // tab2
    // "subject.subject_ids": {
    //   handler() {
    //     this.getQualifiers();
    //   }
    // },
    "subject.period_type": {
      handler() {
        this.subject.to = "";
        this.subject.from = "";

        if (this.subject.period_type == 1) {
          this.subject.academic_semester_ids = [];
          this.subject.academic_quarters_ids = [];
        }

        if (this.subject.period_type == 2) {
          this.getSemesters();
        }

        if (this.subject.period_type == 3) {
          this.activateDatePicker();
          this.subject.academic_semester_ids = [];
          this.subject.academic_quarters_ids = [];
        }
      },
    },
    "subject.academic_semester_ids": {
      handler() {
        this.getQuarters();
      },
    },

    // tab3
    "qualifier.subject_ids": {
      handler() {
        this.getQualifiers();
      },
    },
    "qualifier.period_type": {
      handler() {
        this.qualifier.to = "";
        this.qualifier.from = "";

        if (this.qualifier.period_type == 1) {
          this.qualifier.academic_semester_ids = [];
          this.qualifier.academic_quarters_ids = [];
        }

        if (this.qualifier.period_type == 2) {
          this.getSemesters();
        }

        if (this.qualifier.period_type == 3) {
          this.activateDatePicker();
          this.qualifier.academic_semester_ids = [];
          this.qualifier.academic_quarters_ids = [];
        }
      },
    },
    "qualifier.academic_semester_ids": {
      handler() {
        this.getQuarters();
      },
    },
  },
  methods: {
    checkTab() {
      if (this.GradeSheetpermission) {
        if (this.tab == 0 || this.tab == null) {
          this.printFinalResultTab();
        } else if (this.tab == 1) {
          this.printAllSubjectsTab();
        } else if (this.tab == 2) {
          this.printSubjectsTab();
        } else {
          this.printQualifierTab();
        }
      } else {
        if (this.tab == 0 || this.tab == null) {
          this.printAllSubjectsTab();
        } else if (this.tab == 1) {
          this.printSubjectsTab();
        } else {
          this.printQualifierTab();
        }
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.tab == 0 || this.tab == 1) {
          if (this.selectAllStudents) {
            this.allSubjects.students_ids = [];
          } else {
            let all = this.students;
            let selected = [];

            all.forEach((student) => {
              selected.push(student.id);
            });
            // this.allSubjects.students_ids = this.students.slice();
            this.allSubjects.students_ids = selected;
          }
        } else if (this.tab == 2) {
          if (this.selectAllStudents) {
            this.subject.students_ids = [];
          } else {
            let all = this.students;
            let selected = [];

            all.forEach((student) => {
              selected.push(student.id);
            });
            // this.allSubjects.students_ids = this.students.slice();
            this.subject.students_ids = selected;
          }
        } else if (this.tab == 3) {
          if (this.selectAllStudents) {
            this.qualifier.students_ids = [];
          } else {
            let all = this.students;
            let selected = [];

            all.forEach((student) => {
              selected.push(student.id);
            });
            // this.allSubjects.students_ids = this.students.slice();
            this.qualifier.students_ids = selected;
          }
        }
      });
    },

    getAllSubjects() {
      axios
        .get(
          this.getApiUrl +
            "/exams/getSubjects/" +
            this.$router.currentRoute.params.classId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.subjects = response.data.data;
        });
    },

    getQualifiers() {
      let subjectsIds = this.qualifier.subject_ids;
      axios
        .post(
          this.getApiUrl + "/getSubjectQualifiersForPrint",
          {
            grade_subject_ids: subjectsIds,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.qualifiers = response.data.data;
        });
    },

    getSemesters() {
      let academicId = this.$router.currentRoute.params.academicYear;
      let periodIds = [];
      periodIds.push(academicId);
      axios
        .post(
          this.getApiUrl + "/school/getAcademicPeriods",
          {
            periods_ids: periodIds,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.semesters = response.data.data;
        });
    },

    getQuarters() {
      let semestersIds = [];
      if (this.tab == 1) {
        semestersIds = this.allSubjects.academic_semester_ids;
      } else if (this.tab == 2) {
        semestersIds = this.subject.academic_semester_ids;
      } else if (this.tab == 3) {
        semestersIds = this.qualifier.academic_semester_ids;
      }
      axios
        .post(
          this.getApiUrl + "/school/getAcademicPeriods",
          {
            periods_ids: semestersIds,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.quarters = response.data.data;
        });
    },

    getAllStudent() {
      axios
        .get(
          this.getApiUrl +
            "/students/listStudentClasses/" +
            this.$router.currentRoute.params.classId +
            "?all_data&only_assigned" +
            "&filter_search=",
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.students = response.data.data.students;
        });
    },

    close() {
      this.tab = 0;
      // this.resetTabs();
      this.$emit("childToParent");
    },

    resetTabs() {
      // reset tab1
      this.allSubjects = {
        period_type: "1",
        from: "",
        to: "",
        academic_semester_ids: [],
        academic_quarters_ids: [],
        students_ids: [],
      };

      // reset tab2
      this.subject = {
        subject_ids: [],
        period_type: "1",
        from: "",
        to: "",
        academic_semester_ids: [],
        academic_quarters_ids: [],
        students_ids: [],
      };

      // reset tab3
      this.qualifier = {
        subject_ids: [],
        qualifier_ids: [],
        period_type: "1",
        from: "",
        to: "",
        academic_semester_ids: [],
        academic_quarters_ids: [],
        students_ids: [],
      };
    },
    // tab0
    printFinalResultTab() {
      if (this.valid) {
        axios
          .post(
            this.getApiUrl +
              "/getFinalResultsStudentsMarksPrint/" +
              this.classId +
              "/" +
              this.academicId,
            this.allSubjects,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              window.open(response.data.data);
              this.close();
            }
          });
      } else {
        this.$refs.form.validate();
      }
    },
    // tab1
    printAllSubjectsTab() {
      if (this.valid) {
        axios
          .post(
            this.getApiUrl +
              "/getSubjctsStudentsMarksPrint/" +
              this.classId +
              "/" +
              this.academicId,
            this.allSubjects,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              window.open(response.data.data);
              this.close();
            }
          });
      } else {
        this.$refs.form.validate();
      }
    },
    // tab2
    printSubjectsTab() {
      if (this.subjectFormValid) {
        axios
          .post(
            this.getApiUrl +
              "/getSubjctsQualifersStudentsMarksPrint/" +
              this.classId +
              "/" +
              this.academicId,
            this.subject,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              window.open(response.data.data);
              this.close();
            }
          });
      } else {
        this.$refs.subjectForm.validate();
      }
    },

    // tab3
    printQualifierTab() {
      if (this.qualifierFormValid) {
        axios
          .post(
            this.getApiUrl +
              "/getSubjctsQualifersExamsStudentsMarksPrint/" +
              this.classId +
              "/" +
              this.academicId,
            this.qualifier,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            if (response.data.status.error == false) {
              window.open(response.data.data);
              this.close();
            }
          });
      } else {
        this.$refs.qualifierForm.validate();
      }
    },

    activateDatePicker() {
      let _this = this;
      $(document).ready(function () {
        $("#from").calendarsPicker({
          dateFormat: "dd/mm/yyyy",

          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.allSubjects.from = dateH;
            } else {
              _this.allSubjects.from = "";
            }
          },
        });
        $("#to").calendarsPicker({
          dateFormat: "dd/mm/yyyy",

          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.allSubjects.to = dateH;
            } else {
              _this.allSubjects.to = "";
            }
          },
        });
        $("#fromTab2").calendarsPicker({
          dateFormat: "dd/mm/yyyy",

          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.subject.from = dateH;
            } else {
              _this.subject.from = "";
            }
          },
        });
        $("#toTab2").calendarsPicker({
          dateFormat: "dd/mm/yyyy",

          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.subject.to = dateH;
            } else {
              _this.subject.to = "";
            }
          },
        });
        $("#fromTab3").calendarsPicker({
          dateFormat: "dd/mm/yyyy",

          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.qualifier.from = dateH;
            } else {
              _this.qualifier.from = "";
            }
          },
        });
        $("#toTab3").calendarsPicker({
          dateFormat: "dd/mm/yyyy",

          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.qualifier.to = dateH;
            } else {
              _this.qualifier.to = "";
            }
          },
        });
      });
    },

    /* validation */
    subjectRequiredRule(subjects) {
      if (subjects.length < 1) {
        return this.$i18n.t("This field is required");
      } else {
        return true;
      }
    },

    semestersRequireRule(items) {
      if (
        this.allSubjects.period_type == "2" ||
        this.subject.period_type == "2" ||
        this.qualifier.period_type == "2"
      ) {
        if (items.length < 1) {
          return this.$i18n.t("This field is required");
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    dateRequiredRule(date) {
      if (this.allSubjects.period_type == "3") {
        if (date == "") {
          return this.$i18n.t("this field is required");
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    startDateRule(start, end) {
      var startDate = moment(start, "DD/MM/YYYY");
      var endDate = moment(end, "DD/MM/YYYY");
      return startDate >= endDate
        ? "Start date should be less than end date"
        : true;
    },
  },
  mounted() {
    this.academicId = this.$router.currentRoute.params.academicYear;
    this.classId = this.$router.currentRoute.params.classId;
    this.activateDatePicker();
    this.getAllSubjects();
    this.getAllStudent();
  },
};
</script>

<style lang="scss" scoped>
.innerDiv label {
  margin-top: 0 !important;
}
.imageDiv {
  img {
    max-width: 40px;
  }
}

@media (max-width: 600px) {
  .imageDiv {
    img {
      max-width: 30px;
    }
  }
  .content {
    font-size: 10px;
  }
}
.content {
  font-weight: normal !important;
}
.radio-section {
  margin-top: 0;
}

label {
  font-size: 14px !important;
  margin-top: 0 !important;
}
</style>
