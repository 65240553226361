<template>
  <v-dialog v-model="AddEditdialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class="main-color">{{ $t(formTitle) }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form v-model="valid" ref="form" class="mt-5">
            <v-row class="space">
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Name") }}</label>
                <v-text-field
                  v-model.trim="editedItem.en.title"
                  @focus="onFocus()"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength50
                  ]"
                  solo
                ></v-text-field>

                <div v-if="validation_errors.en" class="vertical-space">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.en.title"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Notes") }}</label>
                <v-textarea
                  rows="3"
                  solo
                  v-model.trim="editedItem.en.notes"
                  @focus="onFocus()"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Arabic Name") }}</label>
                <v-text-field
                  @focus="onFocus()"
                  solo
                  v-model.trim="editedItem.ar.title"
                  :rules="[CheckArabicCharactersOnly(editedItem.ar.title)]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Arabic Notes") }}</label>
                <v-textarea
                  rows="3"
                  solo
                  v-model.trim="editedItem.ar.notes"
                  @focus="onFocus()"
                  :rules="[CheckArabicCharactersOnly(editedItem.ar.notes)]"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="space">
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel" @click="close">{{ $t("Close") }}</v-btn>
        <v-btn
          class="modal-btn-save"
          @click="save"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: [
    "editedItem",
    "AddEditdialog",
    "formTitle",
    "editedIndex",
    "endPoints"
  ],
  name: "AddEditCategoryComponents",
  mixins: [validationMixin],
  data() {
    return {
      valid: false,
      validation_errors: {
        en: { name: "" },
        ar: { name: "" },
        item_type: "",
        selling_price: "",
        cost: "",
        item_category_id: ""
      },
      loading: false,
      categories: []
    };
  },
  watch: {
    AddEditdialog: {
      handler() {
        if (this.$refs.form) {
          this.resetValidation();
        }
      }
    }
  },

  methods: {
    onFocus() {
      this.validation_errors = {
        en: { name: "" },
        ar: { name: "" },
        item_type: "",
        selling_price: "",
        cost: "",
        item_category_id: ""
      };
    },
    close() {
      this.AddEditdialog = false;
      this.validation_errors = {
        en: { name: "" },
        ar: { name: "" },
        item_type: "",
        selling_price: "",
        cost: "",
        item_category_id: ""
      };
      this.editedItem = {};
      this.$emit("childToParent", false, null, this.editedIndex);
    },

    save() {
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        this.loading = true;
        if (this.editedIndex > -1) {
          axios
            .post(
              this.getApiUrl +
                "/" +
                this.endPoints.edit +
                "/" +
                this.editedItem.id,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token
                }
              }
            )
            .then(response => {
              this.loading = false;
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.AddEditdialog = false;
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
              }
            });
        } else {
          axios
            .post(
              this.getApiUrl + "/" + this.endPoints.create,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token
                }
              }
            )
            .then(response => {
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.editedItem = {
                  en: { name: "" },
                  ar: { name: "" },
                  item_type: "",
                  selling_price: "",
                  cost: "",
                  item_category_id: ""
                };
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
                this.close();
              }

              this.loading = false;
            });
        }
      }
    }
  },
  mounted() {
    axios
      .get(this.getApiUrl + "/itemsCategories?select=yes", {
        headers: {
          Authorization: "Bearer " + localStorage.token
        }
      })
      .then(response => {
        this.categories = response.data.data;
      });
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
@import "../../styles/_forms.scss";
</style>
