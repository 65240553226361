<template>
  <v-dialog v-model="AddEditdialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(formTitle) }} </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" class="mt-5">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Name") }}</label>
                <v-text-field
                  v-model.trim="editedItem.filename"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength50,
                  ]"
                  solo
                ></v-text-field>
                <div v-if="validation_errors.filename">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.filename"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div>
                  <label>{{ $t("Type") }}</label>
                  <v-select
                    :items="[
                      { id: 'file', name: $i18n.t('File') },
                      { id: 'url', name: $i18n.t('Url') },
                    ]"
                    item-text="name"
                    item-value="id"
                    v-model.trim="editedItem.type"
                    :rules="[validationRules.required]"
                    solo
                  >
                  </v-select>
                </div>
                <div v-if="validation_errors.subject_id">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.subject_id"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <div>
                  <label>{{ $t("Category") }}</label>
                  <v-select
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    v-model.trim="editedItem.category_id"
                    :rules="[validationRules.required]"
                    solo
                  >
                  </v-select>
                </div>
                <div v-if="categoryValidation">
                  <p class="red--text">
                    {{ $t("This field is required") }}
                  </p>
                </div>
              </v-col>
              <!-- Weeks -->
              <v-col cols="12" sm="12" md="6">
                <div>
                  <label>{{ $t("Academic Week") }}</label>
                  <v-select :items="academicWeeks" item-text="name" item-value="id" 
                  v-model.trim="editedItem.academic_weeks_id"
                   solo multiple>
                  <template v-slot:selection="{ item, index }">
                    
                    <span v-if="index === 0">{{ item.name.slice(0, 7) }}</span>
                    
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ editedItem.academic_weeks_id.length - 1 }} {{$t("others")}})
                    </span>
                  </template>
                  </v-select>
                </div>
                <div v-if="validation_errors.grade_id">
                  <p class="red--text" v-for="(error, index) in validation_errors.grade_id" :key="index">
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <!-- Grade -->
              <v-col cols="12" sm="12" md="6">
                <div>
                  <label>{{ $t("Grade") }}</label>
                  <v-select
                    :items="grades"
                    item-text="name"
                    item-value="id"
                    v-model.trim="editedItem.grade_id"
                    :rules="[validationRules.required]"
                    @change="
                      editedItem.class_ids = [];
                      getSubjectsUnderGrade;
                    "
                    solo
                  >
                  </v-select>
                </div>
                <div v-if="validation_errors.grade_id">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.grade_id"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <div>
                  <label>{{ $t("Classes") }}</label>
                  <v-select
                    :items="classes"
                    item-text="name"
                    item-value="id"
                    v-model.trim="editedItem.class_ids"
                    :rules="[validationRules.required]"
                    multiple
                    solo
                  >
                  </v-select>
                </div>
                <div v-if="calssesValidation">
                  <p class="red--text">
                    {{ $t("This field is required") }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <div>
                  <label>{{ $t("Subject") }}</label>
                  <v-select
                    :items="subjects"
                    item-text="name"
                    item-value="id"
                    v-model.trim="editedItem.subject_id"
                    :rules="[validationRules.required]"
                    solo
                  >
                  </v-select>
                </div>
                <div v-if="validation_errors.subject_id">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.subject_id"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" v-if="editedItem.type == 'file'">
                <v-chip v-if="fileselect" class="mb-1">
                  {{ fileselect }}
                </v-chip>

                <img
                  :src="editedItem.download_url"
                  v-if="
                    editedIndex > -1 &&
                    fileselect == '' &&
                    editedItem.file_type == 'Image'
                  "
                  width="50px"
                  height="50px"
                  alt=""
                />
                <a
                  :href="editedItem.download_url"
                  v-if="
                    editedIndex > -1 &&
                    fileselect == '' &&
                    editedItem.file_type != 'Image'
                  "
                  target="_blank"
                  >attached File</a
                >

                <input
                  id="myFileBrowser"
                  style="display: none !important"
                  type="File"
                  ref="file"
                  @change="readURL()"
                  :rules="editedIndex == -1 ? [validationRules.required] : []"
                />

                <label class="btn-attach-files" @click="openFileInput()">{{
                  $t("Attach File(s)")
                }}</label>

                <div v-if="validation_errors.file">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.file"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" v-if="editedItem.type == 'url'">
                <label style="display: block">{{ $t("Enter Url") }}</label>
                <v-text-field
                  v-model.trim="editedItem.url"
                  :rules="[validationRules.required]"
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel cancelBtn" @click="close">{{
          $t("Close")
        }}</v-btn>
        <v-btn
          class="modal-btn-save saveBtn"
          @click="save"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackTime"
      :color="snackColor"
      :multi-line="true"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">
        {{ errormsg }}
      </span>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: [
    "editedItem",
    "AddEditdialog",
    "formTitle",
    "editedIndex",
    "endPoints",
    "academic_grade_id",
  ],
  mixins: [validationMixin],
  name: "addEditLibraryItems",
  data() {
    return {
      fileselect: "",
      academicWeeks:[],
      valid: true,
      categoryValidation: false,
      calssesValidation: false,
      validation_errors: {
        filename: "",
        type: "",
        category_id: "",
        grade_id: "",
        subject_id: "",
        class_ids: [],
        url: "",
      },
      loading: false,
      categories: [],
      grades: [],
      subjects: [],
      snackbar: false,
      /* snackbar data */
      snackColor: "",
      snackTime: 7000,
      errormsg: "",
      classes: [],
    };
  },
  watch: {
    AddEditdialog: {
      handler() {
        this.fileselect = "";
        if (this.$refs.form) {
          this.resetValidation();
        }
      },
    },
    "editedItem.grade_id": {
      handler() {
        this.getSubjectsUnderGrade();
      },
    },
  },
  methods: {
    openFileInput() {
      document.getElementById("myFileBrowser").click();
    },
    readURL() {
      this.fileselect = this.$refs.file.files[0].name;
      this.editedItem.file = this.$refs.file.files[0];
    },
    // createBase64Image(fileObject) {
    //   const reader = new FileReader();
    //   var _this = this;
    //   reader.onload = e => {
    //       _this.editedItem.file = e.target.result;
    //   };
    //   reader.readAsDataURL(fileObject);
    // },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
    close() {
      this.AddEditdialog = false;
      this.validation_errors = {
        filename: "",
        type: "",
        category_id: "",
        grade_id: "",
        subject_id: "",
        url: "",
      };
      this.editedItem = {};
      this.$emit("childToParent", false, null, this.editedIndex);
    },

    save() {
      if (this.valid == false) {
        this.validate();
      } else {
        if (
          (this.editedItem.type == "file" &&
            this.editedItem.file &&
            this.editedIndex == -1) ||
          (this.editedIndex > -1 && this.editedItem.path) ||
          this.editedItem.type != "file"
        ) {
          this.loading = true;
          const formData = new FormData();
          if (this.editedItem.file) {
            formData.append("file", this.editedItem.file);
          } else {
            formData.append("url", this.editedItem.url);
          }
          formData.append("filename", this.editedItem.filename);
          formData.append("type", this.editedItem.type);
          formData.append("category_id", this.editedItem.category_id);
          formData.append("grade_id", this.editedItem.grade_id);
          formData.append("subject_id", this.editedItem.subject_id);
          formData.append("class_ids", this.editedItem.class_ids);
          formData.append("academic_weeks_id", this.editedItem.academic_weeks_id);
          if (this.editedIndex > -1) {
            axios
              .post(
                this.getApiUrl +
                  "/" +
                  this.endPoints.edit +
                  "/" +
                  this.editedItem.id,
                formData,
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.token,
                  },
                }
              )
              .then((response) => {
                this.loading = false;
                if (response.data.status.error == true) {
                  Object.assign(
                    this.validation_errors,
                    response.data.status.validation_errors
                  );
                } else {
                  this.AddEditdialog = false;
                  this.$emit(
                    "childToParent",
                    false,
                    response.data.data,
                    this.editedIndex
                  );
                }
              });
          } else {
            axios
              .post(this.getApiUrl + "/" + this.endPoints.create, formData, {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              })
              .then((response) => {
                if (response.data.status.error == true) {
                  Object.assign(
                    this.validation_errors,
                    response.data.status.validation_errors
                  );
                } else {
                  this.editedItem = {
                    filename: "",
                    type: "",
                    category_id: "1",
                    grade_id: "",
                    subject_id: "",
                    url: "",
                  };
                  this.$emit(
                    "childToParent",
                    false,
                    response.data.data,
                    this.editedIndex
                  );
                  this.close();
                }

                this.loading = false;
              });
          }
        } else {
          this.snackbar = true;
          this.errormsg = "Please Add File First";
          this.snackColor = "red";
        }
      }
    },
    getSubjectsUnderGrade() {
      axios
        .get(
          this.getApiUrl +
            "/elibrary/getSubjectsUnderGrade?grade_id=" +
            this.editedItem.grade_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          // console.log(response.data.data.subjects);
          this.subjects = response.data.data.subjects;
          this.classes = response.data.data.classes;
        });
      // if (!edit) {
      //   this.editedItem.class_ids = [];
      // }
    },
    getWeeks(){
      axios
        .get(
          this.getApiUrl +
          "/elibrary/getAcadimcWeeks",
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          // console.log(response.data.data.subjects);
          this.academicWeeks = response.data.data;
        });
    },
  },
  mounted() {
    axios
      .get(this.getApiUrl + "/elibrary/categories?all=all", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.categories = response.data.data;
      });

    // ***************************************
    axios
      .get(this.getApiUrl + "/elibrary/getGradesByFilter", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.grades = response.data.data;
      });
    // ***************************************
    if (this.editedItem.grade_id) {
      this.getSubjectsUnderGrade();
    }
    this.getWeeks();
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
.btn-attach-files {
  display: block;
  cursor: pointer;
  padding: 10px;
  background: #7297fe;
  color: #fff;
  border-radius: 15px;
  text-align: center;
  font-weight: 600;
}
</style>
