<template>
  <v-dialog v-model="AddEditdialog" persistent max-width="850px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t(formTitle) }}</span>
      </v-card-title>

      <v-card-text>
        <v-container :class="currentAppLocale == 'ar' ? 'text-right' : ''">
          <v-form ref="form" v-model="valid" class="mt-5">
            <v-row>
              <v-col sm="4" md="4" xs="4" cols="12">
                <label>{{ $t("First Name") }}</label>
                <v-text-field
                  v-model.trim="editedItem.first_name"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength50,
                  ]"
                  @focus="onFocus()"
                  solo
                ></v-text-field>
                <div v-if="validation_errors.first_name">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.first_name"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col sm="4" md="4" xs="4" cols="12">
                <label>{{ $t("Last Name") }}</label>
                <v-text-field
                  v-model.trim="editedItem.last_name"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength50,
                  ]"
                  @focus="onFocus()"
                  solo
                ></v-text-field>
                <div v-if="validation_errors.last_name">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.last_name"
                    :key="index"
                    @focus="onFocus()"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <v-col sm="4" md="4" xs="4" cols="12">
                <label>{{ $t("Gender") }}</label>
                <div style="margin-left: 12px">
                  <v-row justify="start">
                    <div class="flex-center">
                      <v-radio-group
                        v-model="editedItem.gender"
                        row
                        :rules="[validationRules.required]"
                      >
                        <v-radio
                          :label="$i18n.t('male')"
                          value="male"
                        ></v-radio>
                        <v-radio
                          :label="$i18n.t('female')"
                          value="female"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                  </v-row>
                </div>
                <div v-if="validation_errors.gender">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.gender"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <v-col sm="4" md="4" xs="4" cols="12">
                <!-- <div id="country-code"> -->
                <label>{{ $t("Code") }}</label>
                <v-autocomplete
                  :items="Codes"
                  required
                  @change="hideLabel = true"
                  item-text="title"
                  item-value="value"
                  v-model="editedItem.country_code_id"
                  solo
                  ><template slot="selection" slot-scope="data">
                    <!-- <img src="../../assets/flags/AF.png" />
                    {{ data.title }} -->

                    <v-flex xs3>
                      <v-avatar size="25">
                        <img :src="data.item.icon" />
                      </v-avatar>
                    </v-flex>
                    <v-flex class="ml-3">
                      {{ data.item.title }}
                    </v-flex>
                  </template>
                  <template slot="item" slot-scope="data">
                    <v-list-item-avatar
                      style="width: 20px; min-width: 20px; height: 20px"
                    >
                      <img :src="data.item.icon" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.title"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <!-- </div> -->

                <div v-if="validation_errors.country_code_id">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.country_code_id"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>
              <v-col sm="4" md="4" xs="4" cols="12">
                <label>{{ $t("Mobile") }}</label>
                <v-text-field
                  v-model.trim="editedItem.mobile"
                  @focus="onFocus()"
                  :rules="[
                    validationRules.required,
                    mobileRule(editedItem.country_code_id, editedItem.mobile),
                  ]"
                  solo
                ></v-text-field>
                <div v-if="validation_errors.mobile">
                  <!-- v-for="(error, index) in validation_errors.mobile"
                    :key="index" -->
                  <p class="red--text" @focus="onFocus()">
                    {{ validation_errors.mobile }}
                  </p>
                </div>
              </v-col>
              <v-col sm="4" md="4" xs="4" cols="12">
                <label>{{ $t("Email") }}</label>
                <v-text-field
                  v-model.trim="editedItem.email"
                  @focus="onFocus()"
                  :rules="[validationRules.required, validationRules.email]"
                  solo
                ></v-text-field>
                <div v-if="validation_errors.email">
                  <!-- v-for="(error, index) in validation_errors.email"
                    :key="index" -->
                  <p class="red--text" @focus="onFocus()">
                    {{ validation_errors.email }}
                  </p>
                </div>
              </v-col>

              <v-col sm="6" md="6" xs="6" cols="12">
                <label>{{ $t("Subjects") }}</label>
                <v-autocomplete
                  :items="subjects"
                  @change="hideLabel = true"
                  item-text="name"
                  item-value="id"
                  v-model="editedItem.subjects"
                  multiple
                  solo
                >
                </v-autocomplete>
              </v-col>
              <v-col sm="6" md="6" xs="6" cols="12">
                <label>{{ $t("Grades") }}</label>
                <v-autocomplete
                  :items="grades"
                  @change="hideLabel = true"
                  item-text="name"
                  item-value="id"
                  v-model="editedItem.grades"
                  multiple
                  solo
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel" @click="close">{{ $t("Close") }}</v-btn>
        <v-btn
          class="modal-btn-save"
          @click="save"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: [
    "editedItem",
    "AddEditdialog",
    "formTitle",
    "editedIndex",
    "endPoints",
  ],
  name: "AddEditUserComponent",
  mixins: [validationMixin],
  data() {
    return {
      valid: true,
      validation_errors: {
        first_name: "",
        last_name: "",
        country_code_id: "",
        mobile: "",
        email: "",
        gender: "",
        grades_id: [],
        subjects_id: [],
      },
      loading: false,
      Codes: [],
      nationalities: [],
      grades: [],
      subjects: [],
    };
  },
  watch: {
    AddEditdialog: {
      handler() {
        if (this.$refs.form) {
          this.resetValidation();
        }
      },
    },
  },

  methods: {
    onFocus() {
      this.validation_errors = {
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        country_code_id: "",
        grades_id: [],
        subjects_id: [],
      };
    },
    close() {
      this.AddEditdialog = false;
      this.validation_errors = {
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        country_code_id: "",
        grades_id: [],
        subjects_id: [],
      };
      this.editedItem = {
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        country_code_id: 187,
        grades_id: [],
        subjects_id: [],
      };
      this.passwordType = "password";
      this.$emit("childToParent", false, null, this.editedIndex);
    },

    save() {
      if (this.valid == false) {
        this.validate();
      } else {
        this.loading = true;
        if (this.editedIndex > -1) {
          axios
            .post(
              this.getApiUrl +
                "/" +
                this.endPoints.edit +
                "/" +
                this.editedItem.id,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.loading = false;
              this.resetValidation();
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.AddEditdialog = false;
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
              }
            });
        } else {
          axios
            .post(
              this.getApiUrl + "/" + this.endPoints.create,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.resetValidation();
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.editedItem = {
                  first_name: "",
                  last_name: "",
                  email: "",
                  mobile: "",
                  country_code_id: 187,
                };
                this.$emit(
                  "childToParent",
                  false,
                  response.data.data,
                  this.editedIndex
                );
                this.close();
              }

              this.loading = false;
            });
        }
      }
    },
  },
  mounted() {
    axios.get(this.getApiUrl + "/getCodes").then((response) => {
      this.Codes = response.data.data;
    });
    axios.get(this.getApiUrl + "/getCountries").then((response) => {
      this.nationalities = response.data.data;
    });
    axios
      .get(this.getApiUrl + "/teachers/teachers/grades", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.grades = response.data.data;
      });
    // get subjects
    axios
      .get(this.getApiUrl + "/teachers/subjects", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.subjects = response.data.data;
      });
    this.editedItem.country_code_id = 187;
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

.checkbox {
  margin-top: -25px;
  margin-left: 4px;
}
.passwordIcons {
  margin-top: 50px;
}
.iconpassword {
  border: 1px solid white;
  box-shadow: gray;
}
</style>
