<template>
  <v-dialog v-model="BroadCastDialog" persistent max-width="1000px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("Send BroadCast") }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" class="mt-5">
            <label>{{ $t("Send To") }} :</label>
            <v-row justify="start">
              <v-col cols="4" sm="4" md="2">
                <v-checkbox
                  class="mx-2"
                  :label="$t('Father')"
                  id="father"
                  value="father"
                  :rules="[validationRules.required]"
                  v-model="data.sendToArray"
                  @change="onFoucs"
                ></v-checkbox>
              </v-col>
              <v-col cols="4" sm="4" md="2">
                <v-checkbox
                  class="mx-2"
                  :label="$t('Mother')"
                  id="mother"
                  value="mother"
                  :rules="[validationRules.required]"
                  v-model="data.sendToArray"
                  @change="onFoucs"
                ></v-checkbox>
              </v-col>
              <v-col cols="4" sm="4" md="2">
                <v-checkbox
                  class="mx-2"
                  :label="$t('Student')"
                  id="student"
                  value="student"
                  :rules="[validationRules.required]"
                  v-model="data.sendToArray"
                  @change="onFoucs"
                ></v-checkbox>
              </v-col>
              <div v-if="validation_errors.sendToArray">
                <p
                  class="red--text"
                  v-for="(error, index) in validation_errors.sendToArray"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>
            </v-row>

            <label>{{ $t("Send By") }} :</label>
            <v-row justify="start">
              <v-col cols="4" sm="4" md="2">
                <v-checkbox
                  class="mx-2"
                  :label="$t('SMS')"
                  id="sms"
                  value="sms"
                  :rules="[validationRules.required]"
                  v-model="data.sendByArray"
                  @change="onFoucs"
                ></v-checkbox>
              </v-col>
              <v-col cols="4" sm="4" md="2">
                <v-checkbox
                  class="mx-2"
                  :label="$t('E-mail')"
                  id="email"
                  value="email"
                  :rules="[validationRules.required]"
                  v-model="data.sendByArray"
                  @change="onFoucs"
                ></v-checkbox>
              </v-col>
              <div v-if="validation_errors.sendByArray">
                <p
                  class="red--text"
                  v-for="(error, index) in validation_errors.sendByArray"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>
            </v-row>

            <v-row>
              <v-col cols="12" v-if="data.sendByArray.includes('sms')">
                <label>{{ $t("SMS") }}</label>
                <v-row>
                  <v-col cols="12" style="float: right; text-align: right">
                    <span style="font-size: 10px" v-if="!arabic"
                      >{{ $t("number of chars") }} :
                      {{ data.smsMessage.length }}
                      {{ $t("chars / max :160 character") }}
                      {{ $t("for one message/ number of messages") }}:
                      {{ numberMessage }}</span
                    >
                    <span style="font-size: 10px" v-else
                      >{{ $t("number of chars") }} :
                      {{ data.smsMessage.length }}
                      {{ $t("chars / max :70 character") }}
                      {{ $t("for one message / number of messages") }}:
                      {{ numberMessage }}</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      rows="4"
                      :placeholder="$t('Message')"
                      :rules="[
                        validationRules.required,
                        checkSMSTextValidation
                      ]"
                      v-model="data.smsMessage"
                      solo
                      @focus="onFoucs"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-if="data.sendByArray.includes('email')">
                <label>{{ $t("Email") }}</label>
                <v-text-field
                  :placeholder="$t('Subject')"
                  :rules="[validationRules.required]"
                  v-model="data.emailSubject"
                  solo
                  @focus="onFoucs"
                ></v-text-field>
                <!-- <v-textarea
                  rows="6"
                  placeholder="Message"
                  :rules="[validationRules.required]"
                  v-model="data.emailMessage"
                  solo
                ></v-textarea> -->
                <!-- <ckeditor
                  :editor="editor"
                  v-model="data.emailMessage"
                  :config="editorConfig"
                  class="ck-content"
                  @focus="onFoucs"
                ></ckeditor> -->
                <vue-editor
                  v-model="data.emailMessage"
                  @focus="onFoucs"
                ></vue-editor>
                <p
                  class="red--text"
                  v-if="
                    data.emailMessage == '' &&
                      data.sendByArray.includes('email')
                  "
                >
                  {{ $t("This field is required") }}
                </p>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn class="modal-btn-cancel cancelBtn" @click="close">{{
          $t("Close")
        }}</v-btn>
        <v-btn
          class="modal-btn-save saveBtn"
          @click="send"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Send") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </v-dialog>
</template>
<script>
import { validationMixin } from "../../mixins/validationMixin";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { VueEditor } from "vue2-editor";
import axios from "axios";
export default {
  props: ["BroadCastDialog", "students_ids"],
  mixins: [validationMixin],
  components: {
    VueEditor
  },
  data() {
    return {
      loading: false,
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 3000,
      // editor: ClassicEditor,
      // editorConfig: {
      //   // The configuration of the editor.
      // },

      valid: false,
      data: {
        sendToArray: [],
        sendByArray: [],
        smsMessage: "",
        emailMessage: "",
        emailSubject: ""
      },
      validation_errors: [],
      arabic: false,
      numberMessage: 0
    };
  },
  methods: {
    checkSMSTextValidation() {
      if (
        this.data.smsMessage != null &&
        this.data.smsMessage != undefined &&
        this.data.smsMessage != ""
      ) {
        let format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
        // error = /\d/.test(val) ? false : true;
        // if (!error) {
        //   return "Please write only arabic letters.";
        // }
        for (var i = 0; i < this.data.smsMessage.length; i++) {
          // console.log(val.charAt(i));
          var unicode = this.data.smsMessage.charCodeAt(i);

          if (unicode != 8 && !format.test(this.data.smsMessage[i])) {
            //if the key isn't the backspace key (which we should allow)
            if (unicode != 32) {
              if (
                (unicode < 48 || unicode > 57) &&
                (unicode < 0x0600 || unicode > 0x06ff)
              ) {
                //if not a number or arabic
                this.arabic = false;
              } else {
                this.arabic = true; //disable key press
              }
            }
          }
        }
        if (this.arabic) {
          this.numberMessage =
            this.data.smsMessage.length > 70
              ? Math.ceil(this.data.smsMessage.length / 70)
              : 1;
        } else {
          this.numberMessage =
            this.data.smsMessage.length > 160
              ? Math.ceil(this.data.smsMessage.length / 160)
              : 1;
        }
        console.log(this.numberMessage);
      }
    },
    onFoucs() {
      this.validation_errors = [];
      this.$refs.form.resetValidation();
    },
    close() {
      this.data = {
        sendToArray: [],
        sendByArray: [],
        smsMessage: "",
        emailMessage: "",
        emailSubject: ""
      };
      this.BroadCastDialog = false;
      this.$emit("childToParent");
    },
    send() {
      if (!this.valid) {
        this.validate();
      } else {
        if (
          this.data.sendByArray.includes("email") &&
          this.data.emailMessage == ""
        ) {
          this.validate();
        } else {
          let students_ids = this.students_ids;
          this.loading = true;
          axios
            .post(
              this.getApiUrl + "/students/SendbroadCast",
              { students_ids: students_ids, data: this.data },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token
                }
              }
            )
            .then(response => {
              this.loading = false;
              if (response.data.status.error) {
                this.snack = true;
                this.snackColor = "red";
                this.snackText = response.data.status.message;
                this.validation_errors = response.data.status.validation_errors;
                this.validation_errors["sendToArray"] =
                  response.data.status.validation_errors["data.sendToArray"];
                this.validation_errors["sendByArray"] =
                  response.data.status.validation_errors["data.sendByArray"];
                console.log(this.validation_errors);
              } else {
                this.snack = true;
                this.snackColor = "green";
                this.snackText = response.data.status.message;
                this.close();
              }
            });
        }
      }
    }
  },
  mounted() {}
};
</script>
