var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"cashpointsModal"}},[_c('v-dialog',{staticClass:"cashpoints",attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.AddEditdialog),callback:function ($$v) {_vm.AddEditdialog=$$v},expression:"AddEditdialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t(_vm.formTitle)))])]),_c('v-card-text',[_c('v-container',[_c('v-row',{staticClass:"space"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-tabs',{attrs:{"background-color":"primary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t("Details"))+" ")]),_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t("Assignee"))+" ")])],1),_c('v-card',[_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"contact-name"}},[_vm._v(_vm._s(_vm.$t("Name")))]),_c('v-text-field',{attrs:{"id":"tax-name","placeholder":"","rules":[
                                _vm.validationRules.required,
                                _vm.validationRules.maxLength50,
                              ],"solo":"","dense":""},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.en.name),callback:function ($$v) {_vm.$set(_vm.editedItem.en, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.en.name"}})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"contact-name"}},[_vm._v(_vm._s(_vm.$t("Arabic Name")))]),_c('v-text-field',{attrs:{"id":"tax-name","placeholder":"","rules":[
                                _vm.CheckArabicCharactersOnly(_vm.editedItem.ar.name),
                                _vm.validationRules.maxLength50,
                              ],"solo":"","dense":""},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.ar.name),callback:function ($$v) {_vm.$set(_vm.editedItem.ar, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.ar.name"}})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"contact-name"}},[_vm._v(_vm._s(_vm.$t("Code")))]),_c('v-text-field',{attrs:{"id":"tax-name","placeholder":"","rules":[
                                _vm.validationRules.required,
                                _vm.validationRules.maxLength50,
                              ],"solo":"","dense":""},on:{"focus":function($event){return _vm.onFocus()}},model:{value:(_vm.editedItem.code),callback:function ($$v) {_vm.$set(_vm.editedItem, "code", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.code"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"gender"}},[_vm._v(_vm._s(_vm.$t("Type")))]),_c('div',{staticClass:"flex-center"},[_c('v-radio-group',{attrs:{"row":""},on:{"change":function($event){return _vm.changType()}},model:{value:(_vm.editedItem.type),callback:function ($$v) {_vm.$set(_vm.editedItem, "type", $$v)},expression:"editedItem.type"}},[_c('v-radio',{attrs:{"label":_vm.$t('Cash'),"value":"Cash"}}),_c('v-radio',{attrs:{"label":_vm.$t('Bank'),"value":"Bank"}})],1)],1)])]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"currency"}},[_vm._v(_vm._s(_vm.$t("Select Currency")))]),_c('v-select',{attrs:{"items":_vm.currencies,"chips":"","solo":"","item-text":"text","item-value":"id"},model:{value:(_vm.editedItem.currency),callback:function ($$v) {_vm.$set(_vm.editedItem, "currency", $$v)},expression:"editedItem.currency"}})],1)])],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"select payment"}},[_vm._v(_vm._s(_vm.$t("Select Payment Method")))]),_c('v-select',{attrs:{"items":_vm.paymentMethods,"rules":[
                                _vm.checkMultiSelect(_vm.editedItem.payment_method),
                              ],"chips":"","solo":"","multiple":"","item-text":"text","item-value":"id"},model:{value:(_vm.editedItem.payment_method),callback:function ($$v) {_vm.$set(_vm.editedItem, "payment_method", $$v)},expression:"editedItem.payment_method"}})],1),_c('div',{staticClass:"form-group checkbox"},[_c('v-checkbox',{staticClass:"mx-2",attrs:{"label":_vm.$t('This cash point is active')},model:{value:(_vm.editedItem.active),callback:function ($$v) {_vm.$set(_vm.editedItem, "active", $$v)},expression:"editedItem.active"}})],1)],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[(_vm.editedIndex == -1 && _vm.show == false)?_c('div',{staticClass:"icon-sec text-center assignee-iconDiv"},[_c('v-icon',{staticClass:"main-color",attrs:{"large":""},on:{"click":_vm.showTable}},[_vm._v("add_circle")]),_c('p',{staticClass:"text-center main-color mt-4"},[_vm._v(" "+_vm._s(_vm.$t("Add Assignee"))+" ")])],1):_vm._e(),(_vm.show || _vm.editedIndex != -1)?_c('div',[_c('v-text-field',{attrs:{"label":_vm.$t('Search by Name or ID'),"prepend-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"table-wrapper"},[_c('table',{staticClass:"table table-striped users-table"},[_c('thead',[_c('tr',{class:_vm.currentAppLocale == 'ar'
                                        ? 'text-right'
                                        : ''},[_c('th',[_vm._v(_vm._s(_vm.$t("ID")))]),_c('th',[_vm._v(_vm._s(_vm.$t("Name")))]),_c('th',[_vm._v(_vm._s(_vm.$t("Actions")))])])]),_c('tbody',_vm._l((_vm.allUsersFilter),function(user){return _c('tr',{key:user.id,class:_vm.currentAppLocale == 'ar'
                                        ? 'text-right'
                                        : ''},[_c('td',[_vm._v(_vm._s(user.id))]),_c('td',[_vm._v(_vm._s(user.name))]),_c('td',[_c('v-checkbox',{staticClass:"mx-2",attrs:{"value":user.id,"label":""},model:{value:(_vm.editedItem.users),callback:function ($$v) {_vm.$set(_vm.editedItem, "users", $$v)},expression:"editedItem.users"}})],1)])}),0)]),_c('div',{staticClass:"text-center"},[(_vm.editedIndex != -1)?_c('v-icon',{staticClass:"main-color",class:{ hidden: _vm.showAllUsers },attrs:{"large":""},on:{"click":_vm.getAllUsersMenu}},[_vm._v("add_circle")]):_vm._e()],1)])],1):_vm._e()])],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(_vm.tab == 1)?_c('v-btn',{staticClass:"modal-btn-save previous",on:{"click":function($event){_vm.tab = 0}}},[_vm._v(_vm._s(_vm.$t("Previous")))]):_vm._e(),_c('v-btn',{staticClass:"modal-btn-cancel cancelBtn",on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("Cancel")))]),(_vm.tab == 0)?_c('v-btn',{staticClass:"modal-btn-save",on:{"click":function($event){_vm.tab = 1}}},[_vm._v(_vm._s(_vm.$t("Next")))]):_vm._e(),(_vm.tab == 1)?_c('v-btn',{staticClass:"modal-btn-save saveBtn",attrs:{"loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("Save")))]):_vm._e()],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackColor,"right":true,"top":true},model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.snackText)+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},[_vm._v(_vm._s(_vm.$t("Close")))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }