<template>
  <!-- new -->
  <v-dialog v-model="AddEditdialog" persistent max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("Add Discussion Room") }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form v-model="valid" ref="form" class="mt-5">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Name") }}</label>
                <v-text-field
                  v-model.trim="editedItem.en.name"
                  :rules="[
                    validationRules.required,
                    validationRules.maxLength50,
                  ]"
                  solo
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("Arabic Name") }}</label>
                <v-text-field
                  v-model.trim="editedItem.ar.name"
                  :rules="[
                    CheckArabicCharactersOnly(editedItem.ar.name),
                    validationRules.maxLength50,
                  ]"
                  solo
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label>{{ $t("About") }}</label>
                <v-textarea
                  v-model="editedItem.about"
                  rows="3"
                  solo
                ></v-textarea>
                <div v-if="validation_errors.about">
                  <p
                    class="red--text"
                    v-for="(error, index) in validation_errors.about"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <div>
                  <label>{{ $t("Classes") }}</label>
                  <v-autocomplete
                    :items="groups"
                    item-text="name"
                    item-value="id"
                    v-model.trim="editedItem.class_id"
                    :rules="[validationRules.required_multiple]"
                    :multiple="true"
                    solo
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col cols="12" md="12">
                <p class="value">
                  <strong class="custom-strong"
                    >{{ $t("Valid Until") }}:</strong
                  >
                  <v-datetime-picker
                    solo
                    v-model="editedItem.valid_unitll"
                    dateFormat="yyyy-MM-dd"
                    timeFormat="HH:mm:ss"
                    outlined
                  ></v-datetime-picker>
                </p>
              </v-col>
              <v-col class="d-flex" cols="12" sm="6">
                <div class="form-group checkbox">
                  <v-checkbox
                    v-model="editedItem.need_approve"
                    class="mx-2"
                    :label="$t('Post need approval')"
                  ></v-checkbox>
                </div>
              </v-col>
              <v-col class="d-flex" cols="12" sm="6">
                <div class="form-group checkbox">
                  <v-checkbox
                    v-model="editedItem.replay_need_approve"
                    class="mx-2"
                    :label="$t('Reply need approval')"
                  ></v-checkbox>
                </div>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <div>
                  <label>{{ $t("Moderators") }}</label>
                  <v-autocomplete
                    :items="users"
                    item-text="name"
                    item-value="id"
                    v-model.trim="editedItem.modirators"
                    :multiple="true"
                    solo
                  ></v-autocomplete>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <!-- <v-spacer></v-spacer> -->

        <v-btn class="modal-btn-cancel" @click="close">{{ $t("Close") }}</v-btn>
        <v-btn
          class="modal-btn-save saveBtn"
          @click="save"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: [
    "editedItem",
    "AddEditdialog",
    "formTitle",
    "editedIndex",
    "endPoints",
    "academic_grade_id",
  ],
  mixins: [validationMixin],
  name: "addEditLibraryItems",
  data() {
    return {
      showAllUsers: false,
      show: false,
      tab: null,
      valid: true,
      validation_errors: {},
      loading: false,
      categories: [],
      grades: [],
      subjects: [],
      users: [],
      usersObjects: [],
      search: "",
      groups: [],
    };
  },

  watch: {
    AddEditdialog: function () {
      if (this.$refs.form) {
        this.resetValidation();
      }
    },
    "editedItem.grade_id": {
      handler() {
        this.getSubjectsUnderGrade();
      },
    },
  },
  methods: {
    showTable() {
      this.show = true;
    },
    readURL() {
      this.editedItem.file = this.$refs.file.files[0];
    },

    // createBase64Image(fileObject) {
    //   const reader = new FileReader();
    //   var _this = this;
    //   reader.onload = e => {
    //       _this.editedItem.file = e.target.result;
    //   };
    //   reader.readAsDataURL(fileObject);
    // },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
    close() {
      this.AddEditdialog = false;
      this.validation_errors = {
        en: {
          name: "",
        },
        ar: {
          name: "",
        },
        about: "",
        grade: "",
        need_approve: false,
        date: "",
      };
      this.editedItem = {};
      this.showAllUsers = false;
      this.$emit("childToParent", false, null, this.editedIndex);
    },

    save() {
      if (this.valid == false) {
        this.validate();
      } else {
        this.loading = true;
        if (this.editedIndex > -1) {
          axios
            .post(
              this.getApiUrl +
                "/" +
                this.endPoints.edit +
                "/" +
                this.editedItem.id,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.loading = false;
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.AddEditdialog = false;
                this.$emit("childToParent", false);
              }
            });
        } else {
          axios
            .post(
              this.getApiUrl + "/" + this.endPoints.create,
              this.editedItem,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == true) {
                Object.assign(
                  this.validation_errors,
                  response.data.status.validation_errors
                );
              } else {
                this.editedItem = {
                  name: "",
                  about: "",
                  grade: "",

                  need_approve: false,
                  date: "",
                };
                this.$emit("childToParent", false);
                this.close();
                // window.location.reload();
              }

              this.loading = false;
            });
        }
        this.showAllUsers = false;
      }
    },
    getSubjectsUnderGrade() {
      axios
        .get(
          this.getApiUrl +
            "/elibrary/getSubjectsUnderGrade?grade_id=" +
            this.editedItem.grade_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.subjects = response.data.data;
        });
    },
    getAllUsers() {
      axios
        .get(this.getApiUrl + "/discussionboard/allModirators", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          console.log(response);
          this.users = response.data.data;
          // this.usersObjects = this.users;
        });
    },
  },
  mounted() {
    axios
      .get(this.getApiUrl + "/discussionboard/getClasses", {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      })
      .then((response) => {
        this.groups = response.data.data;
      });

    // ***************************************
    // axios.get(this.getApiUrl + "/teachers/grades").then(response => {
    //   this.grades = response.data.data;
    // });
    // ***************************************
    if (this.editedItem.grade_id) {
      this.getSubjectsUnderGrade();
    }
    this.getAllUsers();
    if (this.editedIndex == -1 && this.editedItem.modirators.length == 0) {
      this.showAllUsers = false;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

.v-card__title {
  margin-bottom: 15px;
}

.icon-sec {
  height: 360px;
  padding-top: 50px;
}

.table {
  height: auto;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f6ff;
}

.table-striped td {
  padding: 5px 5px;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}

.assignee-iconDiv {
  height: 360px;
}
.table-wrapper {
  height: 300px;
  position: relative;
  overflow: auto;
}
.v-card__title {
  margin-bottom: 0 !important;
}
.v-dialog label {
  margin-top: 15px !important;
  margin-bottom: 7px !important;
}
</style>
